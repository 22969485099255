import {
  CLEAR_BACKEND_ERROR_MESSAGE,
  CREATE_ATTRIBUTE_ERROR,
  CREATE_ATTRIBUTE_REQUEST,
  CREATE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE,
  GET_ATTRIBUTE,
  GET_ATTRIBUTES_ERROR,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  UPDATE_ATTRIBUTE_ERROR,
  UPDATE_ATTRIBUTE_REQUEST,
  UPDATE_ATTRIBUTE_SUCCESS,
  GET_CAPEX_LANDINGS_REQUEST,
  GET_CAPEX_LANDINGS_SUCCESS,
  GET_CAPEX_LANDINGS_ERROR
} from "../types";

const initialState = {
  attributes: [],
  capexLandings: [],
  attribute: {},
  isCreating: false,
  isFetching: false,
  isUpdating: false,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, actions) {
  switch (actions.type) {
    case CREATE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        error: {},
        isCreating: true,
      };
    case CREATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        error: {},
        attributes: [...state.attributes, actions.payload]
      };
    case CREATE_ATTRIBUTE_ERROR:
      return {
        ...state,
        isCreating: false,
        error: actions.payload,
      };
    case GET_ATTRIBUTE:
      return {
        ...state,
        attribute: actions.payload,
      };
    case GET_ATTRIBUTES_REQUEST:
      return {
        ...state,
        isFetching: true,
        attributes: [],
      };
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        attributes: actions.payload,
      };
    case GET_ATTRIBUTES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: actions.payload,
      };
    case UPDATE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        attributes: state.attributes.map((item) => {
          if (item.id === actions.payload.id) {
            return actions.payload;
          }
          return item;
        }),
        isUpdating: false,
      };
    case UPDATE_ATTRIBUTE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: actions.payload,
      };
    case DELETE_ATTRIBUTE:
      return {
        ...state,
        attributes: state.attributes.filter(
          (attribute) => attribute.id !== actions.payload
        ),
      };
    case GET_CAPEX_LANDINGS_REQUEST:
      return {
        ...state,
        capexLandings: [],
      };
    case GET_CAPEX_LANDINGS_SUCCESS:
      return {
        ...state,
        capexLandings: actions.payload,
      };
    case GET_CAPEX_LANDINGS_ERROR:
      return {
        ...state,
        error: actions.payload,
      };
    case CLEAR_BACKEND_ERROR_MESSAGE:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
}
