import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";
import {
  GET_RESOURCE_SPECTRUM_REQUEST,
  GET_RESOURCE_SPECTRUM_SUCCESS,
  GET_RESOURCE_SPECTRUM_ERROR,
  UPDATE_RESOURCE_SPECTRUM_REQUEST,
  UPDATE_RESOURCE_SPECTRUM_SUCCESS,
  UPDATE_RESOURCE_SPECTRUM_ERROR,
  CREATE_RESOURCE_SPECTRUM_REQUEST,
  CREATE_RESOURCE_SPECTRUM_SUCCESS,
  CREATE_RESOURCE_SPECTRUM_ERROR,
  DELETE_RESOURCE_SPECTRUM_REQUEST,
  DELETE_RESOURCE_SPECTRUM_SUCCESS,
  DELETE_RESOURCE_SPECTRUM_ERROR,
  GET_PORTFOLIO_SPECTRUM_REQUEST,
  GET_PORTFOLIO_SPECTRUM_SUCCESS,
  GET_PORTFOLIO_SPECTRUM_ERROR,
} from "../types";
import produce from "immer";

const initialState = {
  items: [],
  isFetching: false,
  isUpdating: false,
};

// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_RESOURCE_SPECTRUM_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
      });
    case GET_RESOURCE_SPECTRUM_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = payload || [];
        draftState.isFetching = false;
      });
    case GET_RESOURCE_SPECTRUM_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
      });
    case CREATE_RESOURCE_SPECTRUM_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case CREATE_RESOURCE_SPECTRUM_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = [...draftState.items, payload];
        draftState.isUpdating = false;
      });
    case CREATE_RESOURCE_SPECTRUM_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
      });
    case UPDATE_RESOURCE_SPECTRUM_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case UPDATE_RESOURCE_SPECTRUM_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = updateItemInArray(draftState.items, payload);
        draftState.isUpdating = false;
      });
    case UPDATE_RESOURCE_SPECTRUM_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
      });
    case DELETE_RESOURCE_SPECTRUM_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case DELETE_RESOURCE_SPECTRUM_SUCCESS:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.filter((i) => i.id !== payload);
        draftState.isUpdating = false;
      });
    case DELETE_RESOURCE_SPECTRUM_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
      });
    case GET_PORTFOLIO_SPECTRUM_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
      });
    case GET_PORTFOLIO_SPECTRUM_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.items = payload;
      });
    case GET_PORTFOLIO_SPECTRUM_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
      });
    default:
      return state;
  }
}
