import {
  BILLION_DIVIDER,
  MILLION_DIVIDER,
  THOUSAND_DIVIDER,
} from "../../global/constants";

export const roundToDecimals = (num, decimals) => {
  return (Math.round(num * 100) / 100).toFixed(decimals);
};

// Multiplies any number of numbers and returns the result.
export const multiply = (...numbers) => {
  return numbers.reduce((acc, num) => acc * num, 1);
};

// Sums any number of numbers and returns the result.
export const sum = (...numbers) => {
  return numbers.reduce((acc, num) => acc + num, 0);
};

export const convertToNullIfNaN = (number) => (isNaN(number) ? null : number);

/**
 * Function that formats a big number to return a string that will contain 3 digits with a suffix that indicates the magnitude of the number.
 *
 * Below 1000, the number will be returned without a suffix.
 * Thousand will return the suffix K.
 * Million will return the suffix M.
 * Billion will return the suffix Md.
 * @param {Number} number
 */
export function formatBigNumberWithSuffix(number, roundNumber = true) {
  const properNumber = roundNumber ? Math.round(number) : number;
  const args = createArgsForDivideFunction(properNumber);
  return args.length ? divideNumberAndAddSuffix(...args) : properNumber;
}

function createArgsForDivideFunction(number) {
  const numberLength = Math.floor(number).toString().length;
  if (numberLength >= 10) {
    return [number, BILLION_DIVIDER, "Md"];
  } else if (numberLength >= 7) {
    return [number, MILLION_DIVIDER, "M"];
  } else if (numberLength >= 4) {
    return [number, THOUSAND_DIVIDER, "K"];
  } else return [];
}

function divideNumberAndAddSuffix(number, divider, suffix) {
  const dividedNumber = number / divider;
  return `${dividedNumber.toFixed(1)}${suffix}`;
}

export const safeDivide = (dividend, divisor) => {
  if (divisor == 0) return undefined;

  return dividend / divisor;
};

const TOP_ALLOWED_RESIUDE = 1e-3;
const BOTTOM_ALLOWED_RESIUDE = 1e-16;
export const subtractWithoutResidue = (first, second) => {
  const difference = first - second;
  const absoluteDifference = Math.abs(difference);
  const hasResidue =
    absoluteDifference <= TOP_ALLOWED_RESIUDE &&
    absoluteDifference >= BOTTOM_ALLOWED_RESIUDE;

  return hasResidue ? 0 : difference;
};
