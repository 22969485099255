import { mapUsagesAndDimensions } from "../../utils/MappingUtils/MappingUtils";
import {
  GET_CUSTOM_DETAILS_ERROR,
  GET_CUSTOM_DETAILS_REQUEST,
  GET_CUSTOM_DETAILS_SUCCESS,
  GET_CUSTOM_SUPPLY_ERROR,
  GET_CUSTOM_SUPPLY_REQUEST,
  GET_CUSTOM_SUPPLY_SUCCESS,
  GET_CUSTOM_SUPPLY_BY_PROJECTS_REQUEST,
  GET_CUSTOM_SUPPLY_BY_PROJECTS_SUCCESS,
  GET_CUSTOM_SUPPLY_BY_PROJECTS_ERROR,
  GET_CUSTOM_TIMELINE_ERROR,
  GET_CUSTOM_TIMELINE_REQUEST,
  GET_CUSTOM_TIMELINE_SUCCESS,
  GET_CUSTOM_USAGES_ERROR,
  GET_CUSTOM_USAGES_REQUEST,
  GET_CUSTOM_USAGES_SUCCESS,
  GET_FULL_DETAILS_ERROR,
  GET_FULL_DETAILS_REQUEST,
  GET_FULL_DETAILS_SUCCESS,
  GET_FULL_TIMELINE_ERROR,
  GET_FULL_TIMELINE_REQUEST,
  GET_FULL_TIMELINE_SUCCESS,
  GET_FULL_USAGES_ERROR,
  GET_FULL_USAGES_REQUEST,
  GET_FULL_USAGES_SUCCESS,
  GET_TIMELINE_NON_LABOR_RESOURCE_IDS_ERROR,
  GET_TIMELINE_NON_LABOR_RESOURCE_IDS_SUCCESS,
} from "../types";
import produce from "immer";

const initialState = {
  fullTimeline: [],
  fullDetails: {},
  fullUsages: [],
  fullDimensions: [],
  customTimeline: [],
  customDetails: {},
  customUsages: [],
  customDimensions: [],
  assignedNonLabor: [],
  customSupply: [],
  customSupplyByProjects: [],
  isFetchingTimeline: false,
  isFetchingDetails: false,
  isFetchingUsages: false,
  isFetchingCustomSupply: false,
  isFetchingCustomSupplyByProjects: false,
  error: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FULL_TIMELINE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = true;
        draftState.fullTimeline = [];
        draftState.error = "";
      });
    case GET_FULL_TIMELINE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = false;
        draftState.fullTimeline = payload;
      });
    case GET_FULL_TIMELINE_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = false;
        draftState.error = payload;
      });
    case GET_FULL_DETAILS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = true;
        draftState.fullDetails = {};
        draftState.error = "";
      });
    case GET_FULL_DETAILS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = false;
        draftState.fullDetails = payload;
      });
    case GET_FULL_DETAILS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = false;
        draftState.error = payload;
      });
    case GET_FULL_USAGES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingUsages = true;
        draftState.fullUsages = [];
        draftState.fullDimensions = [];
        draftState.error = "";
      });
    case GET_FULL_USAGES_SUCCESS:
      const {
        usages: fullUsages,
        dimensions: fullDimensions,
      } = mapUsagesAndDimensions(payload);

      return produce(state, (draftState) => {
        draftState.isFetchingUsages = false;
        draftState.fullUsages = fullUsages;
        draftState.fullDimensions = fullDimensions;
      });
    case GET_FULL_USAGES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingUsages = false;
        draftState.error = payload;
      });
    case GET_CUSTOM_TIMELINE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = true;
        draftState.customTimeline = [];
        draftState.error = "";
      });
    case GET_CUSTOM_TIMELINE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = false;
        draftState.customTimeline = payload;
      });
    case GET_CUSTOM_TIMELINE_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingTimeline = false;
        draftState.error = payload;
      });
    case GET_CUSTOM_DETAILS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = true;
        draftState.customDetails = {};
        draftState.error = "";
      });
    case GET_CUSTOM_DETAILS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = false;
        draftState.customDetails = payload;
      });
    case GET_CUSTOM_DETAILS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingDetails = false;
        draftState.error = payload;
      });
    case GET_CUSTOM_USAGES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingUsages = true;
        draftState.customUsages = [];
        draftState.customDimensions = [];
        draftState.error = "";
      });
    case GET_CUSTOM_USAGES_SUCCESS:
      const {
        usages: customUsages,
        dimensions: customDimensions,
      } = mapUsagesAndDimensions(payload);

      return produce(state, (draftState) => {
        draftState.isFetchingUsages = false;
        draftState.customUsages = customUsages;
        draftState.customDimensions = customDimensions;
      });
    case GET_CUSTOM_USAGES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingUsages = false;
        draftState.error = payload;
      });
    case GET_TIMELINE_NON_LABOR_RESOURCE_IDS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.assignedNonLabor = payload;
      });
    case GET_TIMELINE_NON_LABOR_RESOURCE_IDS_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
      });
    case GET_CUSTOM_SUPPLY_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingCustomSupply = true;
      });
    case GET_CUSTOM_SUPPLY_SUCCESS:
      return produce(state, (draftState) => {
        draftState.customSupply = payload;
        draftState.isFetchingCustomSupply = false;
      });
    case GET_CUSTOM_SUPPLY_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingCustomSupply = false;
        draftState.error = payload;
      });
    case GET_CUSTOM_SUPPLY_BY_PROJECTS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingCustomSupplyByProjects = true;
      });
    case GET_CUSTOM_SUPPLY_BY_PROJECTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.customSupplyByProjects = payload;
        draftState.isFetchingCustomSupplyByProjects = false;
      });
    case GET_CUSTOM_SUPPLY_BY_PROJECTS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingCustomSupplyByProjects = false;
        draftState.error = payload;
      });
    default:
      return state;
  }
};
