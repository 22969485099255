import updateQuantities from "../../utils/StructureUtils/StructureUtils";
import {
  DELETE_QUANTITY,
  //GET_QUANTITIES,
  GET_QUANTITIES_ERROR,
  GET_QUANTITIES_REQUEST,
  GET_QUANTITIES_SUCCESS,
  GET_QUANTITY,
  UPDATE_QUANTITY_ERROR,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_QUANTITY_SUCCESS,
} from "../types";

const initialState = {
  quantities: [],
  penalties: [],
  quantity: {},
  isFetching: false,
};
// eslint-disable-next-line
export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_QUANTITY:
      return {
        ...state,
        quantity: actions.payload,
      };
    case GET_QUANTITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        quantities: [],
        error: "",
      };
    case GET_QUANTITIES_SUCCESS:
      return {
        ...state,
        quantities: actions.payload.quantities || [],
        penalties: actions.payload.penalties,
        isFetching: false,
      };
    case GET_QUANTITIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: actions.payload,
      };
    case UPDATE_QUANTITY_REQUEST:
      return {
        ...state,
        error: "",
      };
    case UPDATE_QUANTITY_SUCCESS:
      const updatedQuantities = updateQuantities(state.quantities, actions);
      return {
        ...state,
        quantities: updatedQuantities,
      };
    case UPDATE_QUANTITY_ERROR:
      return {
        ...state,
        error: actions.payload,
      };
    case DELETE_QUANTITY:
      return {
        ...state,
        quantities: state.quantities.filter(
          (quantity) => quantity.id !== actions.payload
        ),
      };
    default:
      return state;
  }
}
