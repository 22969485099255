import React from "react";
import { Navigate } from "react-router-dom";

const PermissionGuard = ({ permission, children }) => {
  if (!permission) return children;

  const isPermitted = permission();

  if (isPermitted) {
    return children;
  }

  return <Navigate to="/" />;
};

export default PermissionGuard;
