import {
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_ERROR,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  GENERATE_PASSWORD_REQUEST,
  GENERATE_PASSWORD_SUCCESS,
  GENERATE_PASSWORD_ERROR
} from "../types";

const initialState = {
  items: [],
  roles: [],
  isFetching: false,
  isPasswordGenerating: false,
  error: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload],
        isFetching: false,
      };
    case USER_CREATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        items: [],
        error: "",
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        items: payload,
        isFetching: false,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_USER_ROLES_REQUEST:
      return {
        ...state,
        error: "",
      };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload,
      };
    case GET_USER_ROLES_ERROR:
      return {
        ...state,
        error: payload,
      };
    case USER_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload),
        isFetching: false,
      };
    case USER_DELETE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }
          return item;
        }),
        isFetching: false,
      };
    case USER_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GENERATE_PASSWORD_REQUEST:
      return {
        ...state, 
        isPasswordGenerating: true,
      }
    case GENERATE_PASSWORD_SUCCESS:
      return {
        ...state, 
        isPasswordGenerating: false,
      }
    case GENERATE_PASSWORD_ERROR:
      return {
        ...state, 
        isPasswordGenerating: false,
      }
    default:
      return state;
  }
};
