/**
 * Safely divides two numbers and returns the result. If either the numerator or the denominator is falsy (e.g., 0, undefined, null),
 * the function returns 0 to avoid division by zero errors.
 *
 * @param {number} numerator - The number to be divided.
 * @param {number} denominator - The number by which to divide.
 * @returns {number} The result of the division, or 0 if division is not possible.
 */
export const safeDivision = (numerator, denominator) => {
  if (!numerator || !denominator) return 0;
  return numerator / denominator;
};

/**
* Rounds a number to the nearest value based on the specified decimal places.
*
* @param {number} num - The number to round.
* @param {number} decimalPlaces - The number of decimal places to round to, default is 0 for rounding to the nearest integer.
* @returns {number} - The rounded number.
*/
export const roundToDecimalPlace = (num, decimalPlaces = 0) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

/**
* Rounds a number based on a specific decimal decision. For example, if rounding 4.86 with 1 decimal decision,
* the result should be 4.9.
*
* @param {number} num - The number to round.
* @param {number} decimalDecision - The decimal place to consider for rounding.
* @returns {number} - The rounded number.
*/
export const roundBasedOnDecimalDecision = (num, decimalDecision) => {
  return roundToDecimalPlace(num, decimalDecision);
};


