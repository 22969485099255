import React, { Component } from "react";

import "./App.scss";
import "./App.css";
import "./bootstrap4.css";

import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Logout from "./pages/user-management/Logout/Logout";
import SecuredRoute from "./securityUtils/SecuredRoute";

import { registerLicense } from "@syncfusion/ej2-base";
import TailSpinLoader from "./components/TailSpinLoader/TailSpinLoader";
import { toast } from "react-toastify";
import ConfettiComponent from "./components/Confetti/ConfettiShower";
import ForgotPasswordPage from "./pages/user-management/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/user-management/ResetPasswordPage/ResetPasswordPage";
import Toaster from "./components/UI/Toaster/Toaster";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5UdEdiXXtZdHdTQmFZ"
);

const loading = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <TailSpinLoader/>
    </div>
  );
};

const LoginPage = React.lazy(() =>
  import("./pages/user-management/LoginPage/LoginPage")
);
const LayoutPage = React.lazy(() => import("./pages/LayoutPage/LayoutPage"));

class App extends Component {

  componentDidMount() {
    window.addEventListener('online', () => toast.success('Connection was restored'));
    window.addEventListener('offline', () => toast.error('Connection was lost'));
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <React.Suspense fallback={loading()}>
            <Routes>
              <Route path="/login" name="Login Page" element={<LoginPage />} />
              <Route path="/forgot-password" name="Forgot Password Page" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" name="Set Password Page" element={<ResetPasswordPage />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route
                path="*"
                name="Office"
                element={
                  <SecuredRoute 
                    component={LayoutPage}
                    route={window.location.pathname + window.location.search}
                  />
                }
              />
            </Routes>
          </React.Suspense>

          <ConfettiComponent />
          <Toaster />
        </Router>
      </Provider>
    );
  }
}
export default App;
