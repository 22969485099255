import React from "react";
import { Link } from "react-router-dom";
import Auxiliary from "../../hoc/Auxiliary";

const Button = (properties) => {
  let buttonElement = null;
  const {loading, tooltipContent, ...props} = properties;

  switch (props.type) {
    case "button":
      buttonElement = (
        <button
          {...props}
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
        >
          {props.name}
        </button>
      );
      break;
    case "action":
      buttonElement = (
        <button
          {...props}
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
        >
          {props.name}
        </button>
      );
      break;
    case "button-icon":
      buttonElement = (
        <button
          {...props}
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
        >
          <div className="d-flex d-row">{props.children}</div>
        </button>
      );
      break;
    case "link":
      buttonElement = (
        <Link
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
          to={props.to}
        >
          {props.name}
        </Link>
      );
      break;
    case "link-icon":
      buttonElement = (
        <Link
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
          to={props.to}
        >
          <div className="d-flex d-row">{props.children}</div>
        </Link>
      );
      break;
    case "loader":
      buttonElement = (
        <Link
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
          to={props.to}
        >
          {props.children}
        </Link>
      );
      break;
    case "submit":
      buttonElement = (
        <Auxiliary>
          {loading ? (
            <button 
              type="submit" 
              disabled 
              className="btn btn-fourth btn-block" 
              id={props.id ? props.id : ""}
            >
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          ) : (
            <button 
              type="submit" 
              disabled={props.disabled} 
              className="btn btn-block btn-fourth mx-auto" 
              id={props.id ? props.id : ""}
            >
              {props.children}
            </button>
          )}
        </Auxiliary>
      );
      break;

    case "button-loader":
      buttonElement = (
        <Auxiliary>
          
          {loading ? (
            <button
              {...props}
              type="button"
              width="23px"
              disabled
              data-toggle="tooltip"
              data-placement="bottom"
              title="Processing your request... Please wait few minutes."
              className={props.className}
            >
              <i className="fas fa-circle-notch fa-spin"></i>
            </button>
          ) : (
            <button
              {...props}
              data-toggle="tooltip"
              data-placement="bottom"
              title={tooltipContent}
              type="button"
              className={props.className}
            >
              {props.children}
            </button>
          )}
        </Auxiliary>
      );
      break;

    default:
      buttonElement = (
        <button
          {...props}
          type="button"
          className={`btn btn-${props.color} shadow-sm m-1`}
        >
          {props.name}
        </button>
      );
      break;
  }
  return <div>{buttonElement}</div>;
};

export default Button;
