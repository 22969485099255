import { GENERIC_NONLABOR, TEAMS } from "../../global/constants";
import {
  GET_PORTFOLIO_USAGE_REQUEST,
  GET_PORTFOLIO_USAGE_SUCCESS,
  GET_PORTFOLIO_USAGE_ERROR,
  GET_SCENARIO_USAGE_REQUEST,
  GET_SCENARIO_USAGE_SUCCESS,
  GET_SCENARIO_USAGE_ERROR,
  DELETE_RESOURCE_ERROR,
  CREATE_RESOURCE_REQUEST,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_ERROR,
  GET_RESOURCES_BY_SCENARIO_ID_REQUEST,
  GET_RESOURCES_BY_SCENARIO_ID_SUCCESS,
  GET_RESOURCES_BY_SCENARIO_ID_ERROR,
  GET_COLLABS_REQUEST,
  GET_COLLABS_SUCCESS,
  GET_COLLABS_ERROR,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_ERROR,
  CLEAR_RESOURCES,
  CLEAR_BACKEND_ERROR_MESSAGE,
  CREATE_MANAGER_REQUEST,
  CREATE_MANAGER_SUCCESS,
  CREATE_MANAGER_ERROR,
  DELETE_MANAGER,
  CREATE_COLLABS_REQUEST,
  CREATE_COLLABS_SUCCESS,
  CREATE_COLLABS_ERROR,
  DELETE_COLLAB,
  UPDATE_SIMULATION_USAGE,
  GET_MAPPED_RESOURCES_REQUEST,
  GET_MAPPED_RESOURCES_SUCCESS,
  GET_MAPPED_RESOURCES_ERROR,
  DELETE_RESOURCE_REQUEST,
  DELETE_RESOURCE_SUCCESS,
  GET_SPECIFIC_NON_LABOR_RESOURCES_REQUEST,
  GET_SPECIFIC_NON_LABOR_RESOURCES_SUCCESS,
  GET_SPECIFIC_NON_LABOR_RESOURCES_ERROR,
  GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_REQUEST,
  GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_SUCCESS,
  GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_ERROR,
  GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_REQUEST,
  GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_SUCCESS,
  GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_ERROR,
  GET_DIMENSION_RESOURCES_SUCCESS,
  GET_DIMENSION_RESOURCES_ERROR,
  GET_DIMENSION_RESOURCES_REQUEST,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_MY_TEAMS_REQUEST,
  GET_MY_TEAMS_SUCCESS,
  GET_MY_TEAMS_ERROR,
  RESET_USAGES,
  SIMULATION_PROCESS,
  SIMULATION_SUCCESS,
  SIMULATION_ERROR,
  FETCH_SCENARIO_USAGE,
  FETCH_SHIFTS_REQUEST,
  FETCH_ALL_SHIFTS_SUCCESS,
  FETCH_ALL_SHIFTS_ERROR,
  MUTATE_SHIFTS_WITHOUT_RERENDER,
  UPDATE_SHIFTS_REQUEST,
  UPDATE_SHIFTS_SUCCESS,
  UPDATE_SHIFTS_ERROR,
  FETCH_ALL_BUDGET_ENVELOPES_REQUEST,
  FETCH_ALL_BUDGET_ENVELOPES_SUCCESS,
  FETCH_ALL_BUDGET_ENVELOPES_ERROR,
  UPDATE_BUDGET_ENVELOPE_REQUEST,
  UPDATE_BUDGET_ENVELOPE_SUCCESS,
  UPDATE_BUDGET_ENVELOPE_ERROR,
} from "../types";
import produce from "immer";
import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";

const initialState = {
  mappedResources: [],
  resourcesByScenarioId: [],
  collabs: [],
  specificResources: [],
  portfolioCollabsAndSpecificResources: [],
  isPortfolioCollabsAndSpecificResourcesFetching: false,
  collabsAndSpecificResourcesOfUser: [],
  isCollabsAndSpecificResourcesOfUserFetching: false,
  teams: [],
  myTeams: [],
  scenarioUsage: [],
  isUsageFetching: false,
  portfolioUsage: [],
  simulationUsage: [],
  remainingCapacity: [],
  simulatedCapacity: [],
  isFetching: false,
  isCreating: false,
  isDeletion: false,
  error: {},
  dimensionResources: [],
  isDimensionResourcesFetching: false,
  shifts: [],
  isFetchingShifts: false,
  budgetEnvelope:{},
  budgetEnvelopes: [],
  isFetchingBudgetEnvelopes: false,
  isUpdatingShifts: false,
  isFetchingScenarioResources: false,
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_RESOURCE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_RESOURCE_SUCCESS:
      const tmpMappedRes = [...state.mappedResources];
      if (
        action.payload.entityType === TEAMS ||
        action.payload.entityType === GENERIC_NONLABOR
      ) {
        tmpMappedRes.push({
          managers: [],
          mappedGenericWithSpecifics: {
            parent: action.payload,
            childs: [],
          },
        });
      }

      return {
        ...state,
        isCreating: false,
        mappedResources: tmpMappedRes,
      };
    case CREATE_RESOURCE_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.payload,
      };
    case CREATE_MANAGER_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case CREATE_MANAGER_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.payload,
      };
    case CREATE_COLLABS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isCreating = true;
      });
    case CREATE_COLLABS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isCreating = false;
        draftState.mappedResources = draftState.mappedResources.map((item) => {
          if (
            item.mappedGenericWithSpecifics.parent.id === action.payload.teamId
          ) {
            const existingChildIds = item.mappedGenericWithSpecifics.childs.map(
              (c) => c.id
            );
            const idsToAdd = action.payload.collabsId.filter(
              (id) => !existingChildIds.includes(id)
            );
            const collabsToAdd = action.payload.isLabor
              ? draftState.collabs.filter((c) => idsToAdd.includes(c.id))
              : draftState.specificResources.filter((c) =>
                  idsToAdd.includes(c.id)
                );
            item.mappedGenericWithSpecifics.childs = [
              ...item.mappedGenericWithSpecifics.childs,
              ...collabsToAdd,
            ];
          }
          return item;
        });
      });
    case CREATE_COLLABS_ERROR:
      return produce(state, (draftState) => {
        draftState.isCreating = false;
        draftState.error = action.payload;
      });
    case UPDATE_RESOURCE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.error = "";
      });
    case UPDATE_RESOURCE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.mappedResources = draftState.mappedResources.map((item) => {
          if (item.mappedGenericWithSpecifics.parent.id === action.payload.id) {
            item.mappedGenericWithSpecifics.parent = action.payload;
            if (draftState.collabs.length) {
              const managerIds = action.payload.managerIds
                .split(",")
                .map((id) => +id);
              item.managers = draftState.collabs.filter((c) =>
                managerIds.includes(c.id)
              );
            }
          }
          let childIndex = item.mappedGenericWithSpecifics.childs.findIndex(
            (c) => c.id === action.payload.id
          );
          if (childIndex >= 0) {
            item.mappedGenericWithSpecifics.childs[childIndex] = action.payload;
          }
          return item;
        });
        draftState.collabs = updateItemInArray(
          draftState.collabs,
          action.payload
        );
        draftState.specificResources = updateItemInArray(
          draftState.specificResources,
          action.payload
        );
      });
    case UPDATE_RESOURCE_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = action.payload;
      });
    case GET_MAPPED_RESOURCES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.mappedResources = [];
        draftState.error = "";
      });
    case GET_MAPPED_RESOURCES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.mappedResources = action.payload || [];
      });
    case GET_MAPPED_RESOURCES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = action.payload;
      });
    case GET_RESOURCES_BY_SCENARIO_ID_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingScenarioResources = true;
        draftState.resourcesByScenarioId = [];
        draftState.error = "";
      });
    case GET_RESOURCES_BY_SCENARIO_ID_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingScenarioResources = false;
        draftState.resourcesByScenarioId = action.payload || [];
      });
    case GET_RESOURCES_BY_SCENARIO_ID_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingScenarioResources = false;
        draftState.error = action.payload;
      });
    case GET_COLLABS_REQUEST:
      return {
        ...state,
        collabs: [],
        error: "",
      };
    case GET_COLLABS_SUCCESS:
      const tmpCollabs = [...state.collabs, ...action.payload];
      return {
        ...state,
        collabs: tmpCollabs,
      };
    case GET_COLLABS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SPECIFIC_NON_LABOR_RESOURCES_REQUEST:
      return {
        ...state,
        specificResources: [],
        error: "",
      };
    case GET_SPECIFIC_NON_LABOR_RESOURCES_SUCCESS:
      return {
        ...state,
        specificResources: action.payload || [],
      };
    case GET_SPECIFIC_NON_LABOR_RESOURCES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_REQUEST:
      return {
        ...state,
        portfolioCollabsAndSpecificResources: [],
        isPortfolioCollabsAndSpecificResourcesFetching: true,
        error: "",
      };
    case GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_SUCCESS:
      return {
        ...state,
        portfolioCollabsAndSpecificResources: action.payload || [],
        isPortfolioCollabsAndSpecificResourcesFetching: false,
      };
    case GET_PORFOLIO_COLLABS_AND_SPECIFIC_RESOURCES_ERROR:
      return {
        ...state,
        error: action.payload,
        isPortfolioCollabsAndSpecificResourcesFetching: false,
      };
    case GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_REQUEST:
      return {
        ...state,
        collabsAndSpecificResourcesOfUser: [],
        isCollabsAndSpecificResourcesOfUserFetching: true,
        error: "",
      };
    case GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_SUCCESS:
      return {
        ...state,
        collabsAndSpecificResourcesOfUser: action.payload || [],
        isCollabsAndSpecificResourcesOfUserFetching: false,
      };
    case GET_COLLABS_AND_SPECIFIC_RESOURCES_OF_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isCollabsAndSpecificResourcesOfUserFetching: false,
      };
    case GET_TEAMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        teams: action.payload,
      };
    case GET_TEAMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_MY_TEAMS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
      });
    case GET_MY_TEAMS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.myTeams = action.payload || [];
      });
    case GET_MY_TEAMS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = action.payload;
      });
    case GET_PORTFOLIO_USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
        portfolioUsage: [],
        error: "",
      };
    case GET_PORTFOLIO_USAGE_SUCCESS:
      return {
        ...state,
        portfolioUsage: action.payload,
        isFetching: false,
      };
    case GET_PORTFOLIO_USAGE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_SCENARIO_USAGE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUsageFetching = true;
        draftState.scenarioUsage = [];
        draftState.error = "";
      });
    case GET_SCENARIO_USAGE_SUCCESS:
      action.payload.forEach(
        (u, i) => (action.payload[i].time = new Date(u.date).getTime())
      );
      return produce(state, (draftState) => {
        draftState.isUsageFetching = false;
        draftState.scenarioUsage = action.payload;
      });
    case GET_SCENARIO_USAGE_ERROR:
      return produce(state, (draftState) => {
        draftState.isUsageFetching = false;
        draftState.error = action.payload;
      });
    case UPDATE_SIMULATION_USAGE:
      return produce(state, (draftState) => {
        draftState.simulationUsage = action.payload?.simulationUsage || [];
        draftState.remainingCapacity = action.payload?.remainingCapacity || [];
        draftState.simulatedCapacity = action.payload?.simulatedCapacity || [];
      });
    case SIMULATION_PROCESS:
      return produce(state, (draftState) => {
        draftState.isUsageFetching = true;
      });
    case SIMULATION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.simulationUsage = action.payload?.simulationUsage || [];
        draftState.remainingCapacity = action.payload?.remainingCapacity || [];
        draftState.simulatedCapacity = action.payload?.simulatedCapacity || [];
        draftState.scenarioUsage = action.payload?.scenarioUsage || [];
        draftState.isUsageFetching = false;
      });
    case SIMULATION_ERROR:
      return produce(state, (draftState) => {
        draftState.isUsageFetching = false;
        draftState.error = action.payload;
      });
    case RESET_USAGES:
      return produce(state, (draftState) => {
        draftState.simulationUsage = [];
        draftState.scenarioUsage = [];
        draftState.portfolioUsage = [];
        draftState.simulatedCapacity = [];
        draftState.remainingCapacity = [];
      });
    case DELETE_RESOURCE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isDeletion = true;
      });
    case DELETE_RESOURCE_SUCCESS:
      return produce(state, (draftState) => {
        const tmpMappedResources = draftState.mappedResources.filter(
          (r) => r.mappedGenericWithSpecifics.parent.id !== action.payload
        );
        tmpMappedResources.forEach((r, i) => {
          let childIndex = r.mappedGenericWithSpecifics.childs.findIndex(
            (c) => c.id === action.payload
          );
          if (childIndex >= 0) {
            tmpMappedResources[i].mappedGenericWithSpecifics.childs.splice(
              childIndex,
              1
            );
          }
        });

        draftState.mappedResources = tmpMappedResources;
        draftState.isDeletion = false;
      });
    case DELETE_RESOURCE_ERROR:
      return produce(state, (draftState) => {
        draftState.isDeletion = false;
      });
    case DELETE_MANAGER:
      return {
        ...state,
      };
    case DELETE_COLLAB:
      return produce(state, (draftState) => {
        const index = draftState.mappedResources.findIndex(
          (res) => res.mappedGenericWithSpecifics.parent.id === action.teamId
        );
        draftState.mappedResources[
          index
        ].mappedGenericWithSpecifics.childs = draftState.mappedResources[
          index
        ].mappedGenericWithSpecifics.childs.filter(
          (child) => child.id !== action.payload
        );
      });
    case CLEAR_RESOURCES:
      return {
        ...state,
        mappedResources: [],
      };
    case CLEAR_BACKEND_ERROR_MESSAGE:
      return produce(state, (draftState) => {
        draftState.error = {};
      });
    case GET_DIMENSION_RESOURCES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isDimensionResourcesFetching = true;
      });
    case GET_DIMENSION_RESOURCES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isDimensionResourcesFetching = false;
        draftState.dimensionResources = action.payload;
      });
    case GET_DIMENSION_RESOURCES_ERROR:
      return produce(state, (draftState) => {
        draftState.isDimensionResourcesFetching = false;
      });
    case FETCH_SHIFTS_REQUEST:
      if (state.isFetchingShifts && !state.shifts.length) return state; // prevent state change and re render when nothing needs to be changed

      return produce(state, (draftState) => {
        draftState.isFetchingShifts = true;
        if (draftState.shifts.length) {
          draftState.shifts = [];
        }
      });
    case FETCH_ALL_SHIFTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.shifts = action.payload;
        draftState.isFetchingShifts = false;
      });
    case FETCH_ALL_SHIFTS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingShifts = false;
        draftState.error = action.payload;
      });
    case UPDATE_SHIFTS_REQUEST:
      if (state.isUpdatingShifts) return state;

      return produce(state, (draftState) => {
        draftState.isUpdatingShifts = true;
      });
    case UPDATE_SHIFTS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdatingShifts = false;
        draftState.shifts = draftState.shifts.map((shift) => {
          if (shift.id === action.payload.id) return action.payload;
          return shift;
        });
      });
    case UPDATE_SHIFTS_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdatingShifts = false;
        draftState.error = action.payload;
      });

    case MUTATE_SHIFTS_WITHOUT_RERENDER:
      state.shifts.forEach((shift) => {
        if (action.payload.shiftId === shift.id) {
          shift[action.payload.field] = shift[action.payload.value];
        }
      });
      return state;
    case FETCH_ALL_BUDGET_ENVELOPES_REQUEST:
      if (state.isFetchingBudgetEnvelopes && !state.budgetEnvelopes.length)
        return state; // prevent state change and re render when nothing needs to be changed

      return produce(state, (draftState) => {
        draftState.isFetchingBudgetEnvelopes = true;
        if (draftState.budgetEnvelopes.length) {
          draftState.budgetEnvelopes = [];
        }
      });
    case FETCH_ALL_BUDGET_ENVELOPES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.budgetEnvelopes = action.payload;
        draftState.isFetchingBudgetEnvelopes = false;
      });
    case FETCH_ALL_BUDGET_ENVELOPES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingBudgetEnvelopes = false;
        draftState.error = action.payload;
      });
    case UPDATE_BUDGET_ENVELOPE_REQUEST:
      return {
        ...state,
        isFetchingBudgetEnvelopes: true,
        error: "",
      };
    case UPDATE_BUDGET_ENVELOPE_SUCCESS:
      return {
        ...state,
        isFetchingBudgetEnvelopes: false,
        budgetEnvelope: action.payload,
        error: "",
      };
    case UPDATE_BUDGET_ENVELOPE_ERROR:
      return {
        ...state,
        isFetchingBudgetEnvelopes: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
