import {
  GET_COUNT_PATTERNS,
  GET_COUNT_STRUCTURES,
  GET_COUNT_PROJECTS,
} from "../types";

const initialState = {
  countPatterns: "",
  countStructures: "",
  countProjects: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUNT_PATTERNS:
      return {
        ...state,
        countPatterns: action.payload,
      };
    case GET_COUNT_STRUCTURES:
      return {
        ...state,
        countStructures: action.payload,
      };
    case GET_COUNT_PROJECTS:
      return {
        ...state,
        countProjects: action.payload,
      };
    default:
      return state;
  }
}
