import {
    GET_COLUMN_PERMISSION_ERROR,
    GET_COLUMN_PERMISSION_REQUEST,
    GET_COLUMN_PERMISSION_SUCCESS, UPDATE_COLUMN_PERMISSION_ERROR,
    UPDATE_COLUMN_PERMISSION_REQUEST, UPDATE_COLUMN_PERMISSION_SUCCESS
} from "../types";
import {updateItemWithCustomColumnIdInArray} from "../../utils/FilterUtils/FilterUtils";

const initialState = {
    columnPermissions: {},
    isFetching: false,
    error: "",
};

export default function (state = initialState, {type, payload}) {
    switch (type) {
        case GET_COLUMN_PERMISSION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case GET_COLUMN_PERMISSION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: "",
                columnPermissions: payload,
            };
        case GET_COLUMN_PERMISSION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        case UPDATE_COLUMN_PERMISSION_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case UPDATE_COLUMN_PERMISSION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                error: "",
                columnPermissions: updateItemWithCustomColumnIdInArray(state.columnPermissions, payload),
            };
        case UPDATE_COLUMN_PERMISSION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        default:
            return state;
    }
}