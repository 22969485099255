import {
  DEFINITE_SCOPE,
  CONVERTIBLE_SCOPE,
  IMPORTED_SCOPE,
  INDEFINITE_SCOPE,
} from "../../global/constants";
import { LIBRARY_CSV_IMPORT } from "../../licenseData";
import { allowFeature } from "../FeatureUtils.js/FeatureUtils";

export default function updateQuantities(quantitiesList, actions) {
  const updatedQuantities = quantitiesList.map((scope) => {
    const tmpQuantities = scope.quantities.map((item) => {
      if (item.id === actions.payload.id) {
        return actions.payload;
      }
      return item;
    });
    return {
      ...scope,
      quantities: tmpQuantities,
    };
  });
  return updatedQuantities;
}

export const getStructureTypesForTenant = () => {
  const structureTypes = [
    { value: INDEFINITE_SCOPE, displayValue: INDEFINITE_SCOPE },
    { value: DEFINITE_SCOPE, displayValue: DEFINITE_SCOPE },
    { value: CONVERTIBLE_SCOPE, displayValue: CONVERTIBLE_SCOPE },
  ];

  if (allowFeature(LIBRARY_CSV_IMPORT)) {
    structureTypes.push({
      value: IMPORTED_SCOPE,
      displayValue: IMPORTED_SCOPE,
    });
  }

  return structureTypes;
};
