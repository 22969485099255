import { END_OF_BUSINESS_DAY } from "../../global/constants";
import { getWeekNumber } from "../DateUtils/DateUtils";

/**
 * Formats an array of activity objects by adding new properties and updating existing ones
 *
 * @param {Array} activities - An array of activity objects to format
 * @returns {Array} A new array of formatted activity objects
 */
export const formatActivitiesArray = (activities) => {
  return activities.map((activity) => {
    const finishDate = new Date(activity.finishDate) || new Date();
    finishDate.setHours(END_OF_BUSINESS_DAY);

    const tmpBaselineFinishDate = new Date(
      activity.timeMetrics?.baselineEarlyFinishDate || new Date()
    );
    tmpBaselineFinishDate.setHours(END_OF_BUSINESS_DAY);

    const startDate = new Date(activity.startDate) || new Date();
    startDate.setHours(END_OF_BUSINESS_DAY);

    const timeMetrics = activity.timeMetrics || {};
    const resourceIds = Object.keys(activity.ganttResourceAllocation || {}).map(
      (i) => +i
    );

    return {
      ...activity,
      resources: resourceIds,
      finishDate,
      work: activity?.genericMetrics?.workAtCompletion || 0.0,
      baselineEarlyStartDate: timeMetrics.baselineEarlyStartDate || new Date(),
      baselineEarlyFinishDate: tmpBaselineFinishDate,
      criticality: timeMetrics.criticality || 1,
      freeFloat: timeMetrics.freeFloat || 0,
      totalFloat: timeMetrics.totalFloat || 0,
      startWeekNumber: getWeekNumber(timeMetrics.earlyStartDate),
      finishWeekNumber: getWeekNumber(timeMetrics.earlyFinishDate),
    };
  });
};

export const formatResourceTasksArray = (resourceTasks) => {
  return resourceTasks.map((extendedRT) => {
    const { projectName, resourceTask } = extendedRT;
    const { resource, scheduleActivity } = resourceTask;
    const basicStructure = {
      id: resourceTask.id,
      startDate: resourceTask.start,
      finishDate: resourceTask.finish,
      projectName,
      taskType: resourceTask.taskType,
      resources: [resource.id],
      work: resourceTask.work,
      //workAssigned: genericMetrics?.workAssigned || 0,
      //costAssigned: genericMetrics?.costAssigned || 0,
      duration: resourceTask.duration,
      notes: resourceTask.notes,
      predecessors: resourceTask.predecessors,
      parentUxId: null,
      name: resourceTask.name,
    };
    if (!scheduleActivity) {
      return {
        ...basicStructure,
        baselineEarlyStartDate: new Date(),
        baselineEarlyFinishDate: new Date(),
        progress: 0,
        isExpanded: false,
        criticality: 1,
        totalFloat: 0,
        uxId: null,
      };
    } else {
      const { timeMetrics, ...sa } = scheduleActivity;
      return {
        ...basicStructure,
        baselineEarlyFinishDate: timeMetrics.earlyFinishDate,
        baselineEarlyStartDate: timeMetrics.earlyStartDate,
        progress: sa.progress,
        isExpanded: sa.isExpanded,
        criticality: timeMetrics.criticality,
        totalFloat: timeMetrics.totalFloat,
        uxId: sa.uxId,
      };
    }
  });
};

export const formatTasksArray = (activities) => {
  return Array.from(activities).map((extendedSA) => {
    const scheduleActivity = extendedSA.scheduleActivity;
    const projectName = extendedSA.projectName;
    let tmpBaselineFinishDate = new Date(
      scheduleActivity.timeMetrics.baselineEarlyFinishDate || new Date()
    );
    tmpBaselineFinishDate.setHours(END_OF_BUSINESS_DAY);
    let tmpFinishDate = new Date(scheduleActivity.finishDate || new Date());
    tmpFinishDate.setHours(END_OF_BUSINESS_DAY);
    if (
      scheduleActivity.timeMetrics !== null &&
      scheduleActivity.resourceId !== null
    ) {
      return {
        ...scheduleActivity,
        projectName,
        resources: [scheduleActivity.resourceId],
        finishDate: tmpFinishDate,
        baselineEarlyStartDate:
          scheduleActivity.timeMetrics.baselineEarlyStartDate || new Date(),
        work: scheduleActivity.work || 0.0,
        workAssigned: scheduleActivity.genericMetrics.workAssigned || 0,
        costAssigned: scheduleActivity.genericMetrics.costAssigned || 0,
        baselineEarlyFinishDate: tmpBaselineFinishDate,
        criticality: scheduleActivity.timeMetrics.criticality || 1,
        freeFloat: scheduleActivity.timeMetrics.freeFloat || 0,
        totalFloat: scheduleActivity.timeMetrics.totalFloat || 0,
        startWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyStartDate
        ),
        finishWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyFinishDate
        ),
        parentUxId: null, // fix work of gantt
      };
    } else if (
      scheduleActivity.timeMetrics !== null &&
      scheduleActivity.resourceId !== null &&
      scheduleActivity.lineType !== "M"
    ) {
      return {
        ...scheduleActivity,
        projectName,
        resources: [scheduleActivity.resourceId],
        finishDate: tmpFinishDate,
        baselineEarlyStartDate:
          scheduleActivity.timeMetrics.baselineEarlyStartDate || new Date(),
        baselineEarlyFinishDate: tmpBaselineFinishDate,
        work: scheduleActivity.work || 0.0,
        workAssigned: scheduleActivity.genericMetrics.workAssigned || 0,
        costAssigned: scheduleActivity.genericMetrics.costAssigned || 0,
        criticality: scheduleActivity.timeMetrics.criticality || 1,
        freeFloat: scheduleActivity.timeMetrics.freeFloat || 0,
        totalFloat: scheduleActivity.timeMetrics.totalFloat || 0,
        startWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyStartDate
        ),
        finishWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyFinishDate
        ),
        parentUxId: null, // fix work of gantt
      };
    } else {
      return {
        ...scheduleActivity,
        projectName,
        resources: [scheduleActivity.resourceId],
        finishDate: tmpFinishDate,
        baselineEarlyStartDate:
          scheduleActivity.timeMetrics.baselineEarlyStartDate || new Date(),
        baselineEarlyFinishDate: tmpBaselineFinishDate,
        work: scheduleActivity.work || 0.0,
        workAssigned: scheduleActivity.genericMetrics.workAssigned || 0,
        costAssigned: scheduleActivity.genericMetrics.costAssigned || 0,
        criticality: scheduleActivity.timeMetrics.criticality || 1,
        freeFloat: scheduleActivity.timeMetrics.freeFloat || 0,
        totalFloat: scheduleActivity.timeMetrics.totalFloat || 0,
        startWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyStartDate
        ),
        finishWeekNumber: getWeekNumber(
          scheduleActivity.timeMetrics.earlyFinishDate
        ),
        parentUxId: null, // fix work of gantt
      };
    }
  });
};

export const convertActivityToPositionDTO = (activity) => {
  return {
    id: activity.id,
    position: activity.position,
  };
};

export const convertActivitiesToDimensionDTO = (activities) => {
  return activities.map((a) => {
    return {
      id: a.id,
      name: a.name,
      resourceNames: a.resourceNames,
      dimensions: a.dimensions,
    };
  });
};

export const convertSimulationUsage = (usage, type) => {
  return usage.map((u, index) => {
    // console.log("converting simulation usage: ", u);
    const val = {
      id: index,
      resourceName: u.resourceName,
      resourceType: type,
      work: u.work,
      date: u.localDate,
      weekOfYear: getWeekNumber(u.localDate),
      month: new Date(u.localDate).getMonth() + 1,
      year: new Date(u.localDate).getFullYear(),
      type: type,
      time: new Date(u.localDate).getTime(),
    };
    // console.log("this is the value : ", val);
    return val;
  });
};

export function convertTimelineSimulationUsage(usages, type) {
  const convertedUsages = usages.map((usage) => ({
    resourceName: usage.resourceName,
    resourceType: type,
    work: usage.work,
    date: usage.localDate,
    weekOfYear: getWeekNumber(usage.localDate),
    month: new Date(usage.localDate).getMonth() + 1,
    year: new Date(usage.localDate).getFullYear(),
    type,
    time: new Date(usage.localDate).getTime(),
    scenarioName: usage.scenarioName,
    projectName: usage.projectName,
    fte: usage.fte,
  }));
  return convertedUsages;
}

export const convertSimulationCapacity = (
  simulatedCapacity,
  simulationUsage,
  type
) => {
  return simulatedCapacity.map((u, index) => {
    const usageElementToBeSubstracted = simulationUsage.find(
      (uc) => uc?.resourceName === u?.resourceName && uc?.date === u?.date
    );
    return {
      id: index,
      resourceName: u.resourceName,
      resourceType: type,
      work: calculateNewCapacity(usageElementToBeSubstracted, u),
      date: u.localDate,
      weekOfYear: getWeekNumber(u.localDate),
      month: new Date(u.localDate).getMonth() + 1,
      year: new Date(u.localDate).getFullYear(),
      type: type,
      time: new Date(u.localDate).getTime(),
    };
  });
};

export const calculateNewCapacity = (usageElementToBeSubstracted, u) => {
  return u?.work - usageElementToBeSubstracted?.work > 0
    ? u?.work - usageElementToBeSubstracted?.work
    : 0.0;
};

export const updateProgressOfActivities = (
  activities,
  blockId,
  value,
  children = []
) => {
  const blockIds = [blockId, ...children];
  return activities.map((activity) => {
    if (blockIds.includes(activity.block.id)) {
      activity.progress = value;
    }
    return activity;
  });
};


export function expandedTasksFormatter(tasks) {
  return tasks.map(task => ({
    ...task,
    expanded: true,
  }));
}
