export function filterByCurrentGenericResource(listToBeFiltered, id) {
  return listToBeFiltered.find(
    (element) => element.mappedGenericWithSpecifics.parent.id === id
  );
}

export const updateItemInArray = (array, newItem) => {
  return array.map((item) => {
    if (item.id === newItem.id) {
      const { id, ...rest } = newItem;
      return { ...item, ...rest };
    }
    return item;
  });
}

export const updateItemWithCustomColumnIdInArray = (array, newItem) => {
  return array.map((item) => {
    if (item.id === newItem.id && item.customColumnId === newItem.customColumnId) {
      const {id, ...rest} = newItem;
      return {...item, ...rest};
    }
    return item;
  });
}

export const updateItemNameInArray = (array, newItem) => {
  return array.map((item) => {
    if (item.id === newItem.id) {
      return { ...item, name: newItem.name };
    }
    return item;
  });
};

export const mergeArrays = (firstArray, secondArray) => {
  const newItems = [];
  secondArray.forEach(el => {
    const index = firstArray.findIndex(i => i.id === el.id);
    if (index >= 0) {
      firstArray[index] = el;
    } else {
      newItems.push(el);
    }
  });
  return [...firstArray, ...newItems];
}

export const findArraysDiffById = (array1, array2) => {
  return array1.filter((item1) => !array2.some((item2) => item1.id === item2.id));
}