import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import Form from "../../../components/UI/Form/Form";
import Button from "../../../components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../../services/security-services/SecurityActions";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short (8 chars min)")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[0-9]/, "Password must contain at least 1 digit")
    .matches(/[\W_]/, "Password must contain at least 1 special character"),
  confirmPassword: yup
    .string()
    .required("Confirm your password")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const displayStyle = { display: "inherit" };

export default function ResetPasswordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const request = {
      password: data.newPassword,
      token: searchParams.get("token"),
    };
    const success = await dispatch(resetPassword(request));
    setIsLoading(false);

    if (success) {
      navigate("/login");
      setTimeout(() => {
        toast.success("Your password has been reset");
      }, 0);
    } else {
      toast.error("Failed to reset password");
    }
  };

  return (
    <>
      <Form
        title="Set password"
        formDialog={false}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group">
          <label htmlFor="newPassword">New password:</label>
          <div className="input-group">
            <input
              type={showPassword1 ? "text" : "password"}
              name="newPassword"
              {...register("newPassword")}
              id="newPassword"
              className={classNames("form-control shadow-sm", {
                "is-invalid": errors.newPassword,
              })}
              placeholder="Type new password"
            />
            <button
              className="btn btn-outline-primary shadow-sm"
              type="button"
              id="show_hide_password"
              onClick={() => setShowPassword1(!showPassword1)}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderLeft: "none",
                color: "#212529",
                borderColor: "#ced4da",
                backgroundColor: "white",
              }}
            >
              <i
                className={classNames("fa", {
                  "fa-eye-slash": !showPassword1,
                  "fa-eye": showPassword1,
                })}
              />
            </button>
          </div>
          <div
            className={classNames({ "invalid-feedback": errors.newPassword })}
            style={displayStyle}
          >
            {errors.newPassword?.message}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm password:</label>
          <div className="input-group">
            <input
              type={showPassword2 ? "text" : "password"}
              name="confirmPassword"
              {...register("confirmPassword")}
              id="confirmPassword"
              className={classNames("form-control shadow-sm", {
                "is-invalid": errors.confirmPassword,
              })}
              placeholder="Confirm new password"
            />
            <button
              className="btn btn-outline-primary shadow-sm"
              type="button"
              id="show_hide_password"
              onClick={() => setShowPassword2(!showPassword2)}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderLeft: "none",
                color: "#212529",
                borderColor: "#ced4da",
                backgroundColor: "white",
              }}
            >
              <i
                className={classNames("fa", {
                  "fa-eye-slash": !showPassword2,
                  "fa-eye": showPassword2,
                })}
              />
            </button>
          </div>
          <div
            className={classNames({
              "invalid-feedback": errors.confirmPassword,
            })}
            style={displayStyle}
          >
            {errors.confirmPassword?.message}
          </div>
        </div>

        <Button loading={isLoading} type="submit">
          Set password
        </Button>
      </Form>
    </>
  );
}
