import {
  GET_DEPENDENCY,
  GET_DEPENDENCIES_REQUEST,
  GET_DEPENDENCIES_SUCCESS,
  GET_DEPENDENCIES_ERROR,
  DELETE_DEPENDENCY_REQUEST,
  DELETE_DEPENDENCY_SUCCESS,
  DELETE_DEPENDENCY_ERROR,
  CREATE_DEPENDENCY_REQUEST,
  CREATE_DEPENDENCY_SUCCESS,
  CREATE_DEPENDENCY_ERROR,
  CLEAR_DEPENDENCIES,
  CREATE_ACT_DEPS_REQUEST,
  CREATE_ACT_DEPS_SUCCESS,
  CREATE_ACT_DEPS_ERROR,
  GET_ACT_DEPS_REQUEST,
  GET_ACT_DEPS_SUCCESS,
  GET_ACT_DEPS_ERROR,
  GET_INTER_ACT_DEPS_REQUEST,
  GET_INTER_ACT_DEPS_SUCCESS,
  GET_INTER_ACT_DEPS_ERROR,
  CLEAR_ACCUM_ACTIVITY_DEPENDENCIES,
  GET_ALL_ACT_DEPENDENCIES_REQUEST,
  GET_ALL_ACT_DEPENDENCIES_SUCCESS,
  GET_ALL_ACT_DEPENDENCIES_ERROR
} from "../types";
import { mergeArrays } from "../../utils/FilterUtils/FilterUtils";

const initialState = {
  dependency: {},
  dependencies: [],
  accumDependencies: [],
  activityDeps: [],
  accumActivityDeps: [],
  forSandkey: [],
  isFetching: false,
  isFetchingActDeps: false
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEPENDENCY:
      return {
        ...state,
        dependency: action.payload,
      };
    case CREATE_DEPENDENCY_REQUEST:
      return {
        ...state,
      };
    case CREATE_DEPENDENCY_SUCCESS:
      return {
        ...state,
        dependencies: [...state.dependencies, action.payload],
        accumDependencies: [...state.accumDependencies, action.payload]
      };
    case CREATE_DEPENDENCY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_ACT_DEPS_REQUEST:
      return {
        ...state,
        dependencies: [],
      };
    case CREATE_ACT_DEPS_SUCCESS:
      return {
        ...state,
        activityDeps: [...state.activityDeps, action.payload],
        accumActivityDeps: [...state.accumActivityDeps, action.payload]
      };
    case CREATE_ACT_DEPS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_DEPENDENCIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        dependencies: [],
      };
    case GET_DEPENDENCIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dependencies: action.payload.deps || [],
        accumDependencies: action.payload.accumulate ? 
          mergeArrays(state.accumDependencies, action.payload.deps) : []
      };
    case GET_DEPENDENCIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ALL_ACT_DEPENDENCIES_REQUEST:
      return {
        ...state
      };
    case GET_ALL_ACT_DEPENDENCIES_SUCCESS:
      return {
        ...state,
        accumActivityDeps: mergeArrays(state.accumActivityDeps, action.payload) || []
      };
    case GET_ALL_ACT_DEPENDENCIES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ACT_DEPS_REQUEST:
      return {
        ...state,
        isFetchingActDeps: true,
        activityDeps: [],
      };
    case GET_ACT_DEPS_SUCCESS:
      return {
        ...state,
        isFetchingActDeps: false,
        activityDeps: action.payload || [],
        accumActivityDeps: mergeArrays(state.accumActivityDeps, action.payload || [])
      };
    case GET_ACT_DEPS_ERROR:
      return {
        ...state,
        isFetchingActDeps: false,
        error: action.payload,
      };
    case GET_INTER_ACT_DEPS_REQUEST:
      return { 
        ...state,
      };
    case GET_INTER_ACT_DEPS_SUCCESS:  
      return {
        ...state,
        accumActivityDeps: mergeArrays(state.accumActivityDeps, action.payload)
      };
    case GET_INTER_ACT_DEPS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_DEPENDENCY_REQUEST:
      return {
        ...state,
      };
    case DELETE_DEPENDENCY_SUCCESS:
      return {
        ...state,
        dependencies: state.dependencies.filter(d => d.id != action.payload),
        activityDeps: state.activityDeps.filter(d => d.id != action.payload),
        accumDependencies: state.accumDependencies.filter(d => d.id != action.payload),
        accumActivityDeps: state.accumActivityDeps.filter(d => d.id != action.payload),
      };
    case DELETE_DEPENDENCY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_DEPENDENCIES:
      return {
        ...state,
        dependencies: [],
      };
    case CLEAR_ACCUM_ACTIVITY_DEPENDENCIES: 
    return {
      ...state,
      accumActivityDeps: [],
    };
    default:
      return state;
  }
}
