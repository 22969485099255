import React, { useEffect, useState } from "react";
import { SHOW_CONFETTI_EVENT } from "../../global/constants";
import Confetti from "react-confetti";

function ConfettiComponent() {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    document.addEventListener(SHOW_CONFETTI_EVENT, onShowConfetti);

    return () => {
      document.removeEventListener(SHOW_CONFETTI_EVENT, onShowConfetti);
    };
  }, []);

  const onShowConfetti = () => {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000);
  }

  return (
    showConfetti && (
      <Confetti width={window.innerWidth} height={window.innerHeight} />
    )
  );
}

export default ConfettiComponent;
