import {
  CREATE_OBJECTIVE_ERROR,
  CREATE_OBJECTIVE_REQUEST,
  CREATE_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_ERROR,
  DELETE_OBJECTIVE_REQUEST,
  DELETE_OBJECTIVE_SUCCESS,
  GET_OBJECTIVES_ERROR,
  GET_OBJECTIVES_REQUEST,
  GET_OBJECTIVES_SUCCESS,
  GET_OBJECTIVE_ERROR,
  GET_OBJECTIVE_REQUEST,
  GET_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_ERROR,
  UPDATE_OBJECTIVE_REQUEST,
  UPDATE_OBJECTIVE_SUCCESS,
} from "../types";

const initialState = {
  objectives: [],
  objective: {},
  isFetching: false,
  isUpdating: false,
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_OBJECTIVE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case CREATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        objectives: [payload, ...state.objectives],
      };
    case CREATE_OBJECTIVE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case UPDATE_OBJECTIVE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        objectives: state.objectives.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }
          return item;
        }),
        error: "",
      };
    case UPDATE_OBJECTIVE_ERROR:
      return {
        ...state,
        isUpdating: false,
        objective: {},
        error: payload,
      };
    case GET_OBJECTIVE_REQUEST:
      return {
        ...state,
        isFetching: true,
        objective: {},
        error: "",
      };
    case GET_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objective: payload,
        isFetching: false,
      };
    case GET_OBJECTIVE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_OBJECTIVES_REQUEST:
      return {
        ...state,
        isFetching: true,
        objectives: [],
        error: "",
      };
    case GET_OBJECTIVES_SUCCESS:
      return {
        ...state,
        objectives: payload,
        isFetching: false,
      };
    case GET_OBJECTIVES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case DELETE_OBJECTIVE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case DELETE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        objectives: state.objectives.filter((item) => item.id !== payload),
      };
    case DELETE_OBJECTIVE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    default:
      return state;
  }
}
