import {
  CREATE_PATTERN_COMBINATION_ERROR,
  CREATE_PATTERN_COMBINATION_REQUEST,
  CREATE_PATTERN_COMBINATION_SUCCESS,
  CREATE_PATTERN_ERROR,
  CREATE_PATTERN_REQUEST,
  CREATE_PATTERN_SUCCESS,
  DELETE_PATTERN_COMBINATION_ERROR,
  DELETE_PATTERN_COMBINATION_REQUEST,
  DELETE_PATTERN_COMBINATION_SUCCESS,
  DELETE_PATTERN_ERROR,
  DELETE_PATTERN_REQUEST,
  DELETE_PATTERN_SUCCESS,
  GET_PATTERNS_ERROR,
  GET_PATTERNS_REQUEST,
  GET_PATTERNS_SUCCESS,
  GET_PATTERN_COMBINATIONS_ERROR,
  GET_PATTERN_COMBINATIONS_REQUEST,
  GET_PATTERN_COMBINATIONS_SUCCESS,
  GET_PATTERN_ERROR,
  GET_PATTERN_REQUEST,
  GET_PATTERN_SUCCESS,
  MOVE_PATTERN_ERROR,
  MOVE_PATTERN_REQUEST,
  MOVE_PATTERN_SUCCESS,
  UPDATE_PATTERN_ERROR,
  UPDATE_PATTERN_REQUEST,
  UPDATE_PATTERN_SUCCESS,
} from "../types";

const initialState = {
  isFetching: false,
  patterns: [],
  activePattern: {},
  combinations: [],
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PATTERN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_PATTERN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patterns: [...state.patterns, action.payload],
      };
    case CREATE_PATTERN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_PATTERNS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PATTERNS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patterns: action.payload,
      };
    case GET_PATTERNS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_PATTERN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PATTERN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activePattern: action.payload,
      };
    case GET_PATTERN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_PATTERN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_PATTERN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patterns: state.patterns.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    case UPDATE_PATTERN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_PATTERN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PATTERN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        patterns: state.patterns.filter(({ id }) => id !== action.payload),
      };
    case DELETE_PATTERN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case MOVE_PATTERN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case MOVE_PATTERN_SUCCESS:
      const patterns = state.patterns.map((item) => {
        if (item.id === action.payload.id) {
          const { id, ...rest } = action.payload;

          return {
            ...item,
            ...rest,
          };
        }

        return item;
      });
      return {
        ...state,
        isFetching: false,
        patterns,
      };
    case MOVE_PATTERN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_PATTERN_COMBINATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PATTERN_COMBINATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        combinations: action.payload,
      };
    case GET_PATTERN_COMBINATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CREATE_PATTERN_COMBINATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_PATTERN_COMBINATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        combinations: [...state.combinations, action.payload],
      };
    case CREATE_PATTERN_COMBINATION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DELETE_PATTERN_COMBINATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_PATTERN_COMBINATION_SUCCESS:
      const combinations = state.combinations.filter(
        ({ id }) => id !== action.payload
      );
      return {
        ...state,
        isFetching: false,
        combinations,
      };
    case DELETE_PATTERN_COMBINATION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
