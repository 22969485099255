import { MAXIMUM_SAFE_DURATION, SUMMARY_TYPE } from "../../global/constants";
import { removeTimezoneOffset } from "../DateUtils/DateUtils";
import { extractFieldWithRecursion } from "../ObjectUtils/ObjectUtils";

export const computeAvailableWork = (workPlanned, workAssigned) => {
  const result = workPlanned - workAssigned;
  if (result < 0) return 0;
  return result;
};

export const convertSchedulerTasks = (tasks) =>
  !!tasks.length
    ? tasks.map(({ id, name, start, finish, resource, ...rest }) => ({
        id,
        Name: name,
        StartTime: start,
        EndTime: finish,
        CollabsID: resource.id,
        resource,
        ...rest,
      }))
    : [];

export const getInitialAllocation = (
  numberOfResources,
  resourceIndex,
  lastItemIndex
) => {
  const isLastItem = resourceIndex === lastItemIndex;
  const value = Math.floor(100 / numberOfResources);
  const rest = 100 % numberOfResources;
  return isLastItem ? value + rest : value;
};

const constraintTypeParser = {
  EARLIEST: "startnoearlierthan",
  LATEST: "finishnolaterthan",
};

export const parseConstraintType = (constraint) =>
  constraint && constraint.type in constraintTypeParser
    ? constraintTypeParser[constraint.type]
    : "";

export const taskRecordIsSummary = (taskRecord) => {
  const lineTypeIsS = taskRecord.data.lineType === SUMMARY_TYPE;
  const taskRecordIsParent = taskRecord.isParent;
  return lineTypeIsS || taskRecordIsParent;
};

export const isDimensionalResourceAssigned = (event) => {
  const valueToCheck = event.editorContext.column.originalData.id;
  const keys = Object.keys(
    event.editorContext.record.originalData.ganttResourceAllocation
  );
  return keys.includes(valueToCheck.toString());
};

export function formatRecordAsFixedDimensionDTO(taskRecord) {
  const resourceAllocationEntries = taskRecord.assignments.map((assignment) => [
    assignment.resourceId,
    assignment.units,
  ]);
  const resourceAllocation = Object.fromEntries(resourceAllocationEntries);
  const duration =
    taskRecord.duration < MAXIMUM_SAFE_DURATION
      ? taskRecord.duration
      : taskRecord.originalData.duration;
  return {
    sourceId: taskRecord.id,
    fixedDimension: taskRecord.schedulingMode,
    resourceAllocation,
    duration,
    effort: taskRecord.effort,
    startDate: removeTimezoneOffset(taskRecord.startDate),
    endDate: removeTimezoneOffset(taskRecord.endDate),
    earlyStartDate: removeTimezoneOffset(taskRecord.earlyStartDate),
    earlyEndDate: removeTimezoneOffset(taskRecord.earlyEndDate),
    lateStartDate: removeTimezoneOffset(taskRecord.lateStartDate),
    lateEndDate: removeTimezoneOffset(taskRecord.lateEndDate),
    deadlineDate:
      taskRecord.deadlineDate == null
        ? null
        : removeTimezoneOffset(taskRecord.deadlineDate),
  };
}

export function extractIdsOfTasksTree(tasksTree) {
  return extractFieldWithRecursion(tasksTree, "id", "children");
}
