import { toast } from 'react-toastify';
import { MarkerType } from 'reactflow';
import { SCENARIO_ACTIVITY, LIBRARY_ACTIVITY, SCENARIO_BLOCK, LIBRARY_BLOCK } from "../../global/constants";
import { getOutgoers } from "reactflow";

export const modifyBlocks = (items) => {
  return items.map(item => modifyBlock(item));
};

export const modifyBlock = ({ type: entryType, position, type, ...rest }) => {
  return {
    ...rest,
    id: String(rest.id),
    data: { label: rest.data?.label || rest.name }, // options for subflow blocks and old blocks
    sourcePosition: "right",
    targetPosition: "left",
    entryType,
    type: type || "closableBlock",
    position: position ? position : { x: 100, y: 150 },
    style: {
      border: "1px solid #491777", 
      borderRadius: "16px",
      shadow: "0 8px 2px -6px black",
      fontSize: "16px",
      textAlign: "left",
      lineHeight: "25px",
      display: "inline-block",
    },
  }
}

export const modifyActivity = (
  { type: entryType, position, type, ...rest }, 
  activityType
  ) => {
  return {
    ...rest,
    id: String(rest.id),
    data: { label: rest.name },
    sourcePosition: "right",
    targetPosition: "left",
    entryType,
    type: activityType,
    position: position ? position : { x: 100, y: 150 },
    parentNode: String(rest.block.id),
    extent: "parent",
    style: {
      border: "1px solid",
      borderColor: "#00ff00",
      borderRadius: "16px",
      shadow: "0 8px 2px -6px black",
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "14px",
      lineHeight: "25px",
      height: "Auto",
      display: "inline-block",
    },
  };
};

export const modifyActivities = (items, activityType) => {
  return items.map((item) => modifyActivity(item, activityType));
};

export const modifyDependencies = (dependencies) => {
  if (!dependencies) {
    return [];
  }

  return dependencies
  .filter(dep => dep.inputId !== null && dep.outputId !== null) // error handling for unexpected crashes of mapping when calling modifyDependency
  .map((dep) => modifyDependency(dep));
};

export const modifyDependency = (dependency) => {
  if (!dependency) return; 

  return {
    id: dependency.id.toString(),
    source: dependency.inputId.toString(),
    target: dependency.outputId.toString(),
    arrowHeadType: "arrowclosed",
    label: "Finish-Start",
    animated: false,
    labelStyle: { fill: "#ff0041", fontWeight: 700, height: "20px" },
    type: "edgeWithButton",
    markerEnd: { type: MarkerType.Arrow },
    status: dependency.status,
    data: { isValid: true }
  };
};

export const combineBlocksAndDepdencies = (items, dependencies) => {
  if (
    dependencies !== undefined &&
    dependencies.length > 0 &&
    items.length > 0
  ) {
    return [...modifyBlocks(items), ...modifyDependencies(dependencies)];
  } else if (
    dependencies !== undefined &&
    dependencies.length === 0 &&
    items.length > 0
  ) {
    return [...modifyBlocks(items)];
  } else {
    return [];
  }
};

export const combineActivitiesAndDepdencies = (items, dependencies) => {
  if (
    dependencies !== undefined &&
    dependencies.length > 0 &&
    items.length > 0
  ) {
    return [...modifyActivities(items), ...modifyDependencies(dependencies)];
  } else if (
    dependencies !== undefined &&
    dependencies.length === 0 &&
    items.length > 0
  ) {
    return [...modifyActivities(items)];
  } else {
    return [];
  }
};

export const checkDependencyCreation = (connection, nodes, edges) => {
  const hasCircleDependency = !isValidConnection(nodes, edges, connection);

  if (hasCircleDependency) {
    toast.warning("Circle dependency");
    return;
  }

  const sourceItem = nodes.find(n => n.id === connection.source);
  const targetItem = nodes.find(n => n.id === connection.target);

  if (!sourceItem || !targetItem) return;

  if (sourceItem.type !== targetItem.type) {
    toast.warning("You cannot connect a block with activity directly");
    return;
  }
  
  let result = {
    structureId: sourceItem.structureId || sourceItem.data.structureId,
    request: {
      inputId: connection.source,
      outputId: connection.target,
      name: "",
      arrowHeadType: "arrowclosed",
    }
  };

  if ([SCENARIO_BLOCK, LIBRARY_BLOCK].includes(sourceItem.type)) {
    if (sourceItem.data.structureId !== targetItem.data.structureId) {
      result.isInterscopeDependency = true;
    } else {
      result.structureId = sourceItem.data.structureId;
    }
  }

  if ([SCENARIO_ACTIVITY, LIBRARY_ACTIVITY].includes(sourceItem.type)) {
    result.structureId = sourceItem.structureId;
    
    if (sourceItem.block.id === targetItem.block.id) {
      result.parentBlockId = sourceItem.block.id;
    } else {
      result.isInterblockDependency = true;
    }
  }
  
  return result;
}

export const isValidConnection = (nodes, edges, connection) => {
  const target = nodes.find((node) => node.id === connection.target);
  const hasCycle = (node, visited = new Set()) => {
    if (visited.has(node.id)) return false;

    visited.add(node.id);

    for (const outgoer of getOutgoers(node, nodes, edges)) {
      if (outgoer.id === connection.source) return true;
      if (hasCycle(outgoer, visited)) return true;
    }
  };

  if (!target || target.id === connection.source) return false;
  return !hasCycle(target);
}

export const validateEdges = (nodes, edges) => {
  return edges.map((edge) => {
    const isValid = isValidConnection(nodes, edges, edge);

    return {
      ...edge, 
      style: isValid ? { } : { stroke: "red" },
      data: {...edge.data, isValid },
      markerEnd: { ...edge.markerEnd, color: isValid ? "inherit" : "red" }
    };
  });
}