import {
    GET_VISUAL_PLANNING_REQUEST,
    GET_VISUAL_PLANNING_SUCCESS,
    GET_VISUAL_PLANNING_ERROR,
    UPDATE_VISUAL_PLANNING_REQUEST,
    UPDATE_VISUAL_PLANNING_SUCCESS,
    UPDATE_VISUAL_PLANNING_ERROR,
    DELETE_VISUAL_PLANNING_REQUEST,
    DELETE_VISUAL_PLANNING_SUCCESS,
    DELETE_VISUAL_PLANNING_ERROR
  } from "../types";
  
  const initialState = {
    bgImages: [],
    isFetching: false,
    error: "",
  };
  
  // eslint-disable-next-line
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_VISUAL_PLANNING_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: "",
        };
      case GET_VISUAL_PLANNING_SUCCESS:
        return {
          ...state,
          isFetching: false,
          bgImages: payload || [],
        };
      case GET_VISUAL_PLANNING_ERROR:
        return {
          ...state,
          isFetching: false,
          error: payload,
        };
      case UPDATE_VISUAL_PLANNING_REQUEST:
        return {
          ...state,
          error: "",
        };
      case UPDATE_VISUAL_PLANNING_SUCCESS:
        return {
          ...state,
          bgImages: state.bgImages.map((image) => {
            if (image.id === payload.id) {
              return { ...image, ...payload };
            }
            return image;
          }),
        };
      case UPDATE_VISUAL_PLANNING_ERROR:
        return {
          ...state,
          error: payload,
        };
      case DELETE_VISUAL_PLANNING_REQUEST:
        return {
          ...state,
          error: "",
        };
      case DELETE_VISUAL_PLANNING_SUCCESS:
        return {
          ...state,
          bgImages: state.bgImages.filter((image) => image.id !== payload),    
        };
      case DELETE_VISUAL_PLANNING_ERROR:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  };
  