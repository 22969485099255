import {
  GET_STANDARD_LIBRARIES_REQUEST,
  GET_STANDARD_LIBRARIES_SUCCESS,
  GET_STANDARD_LIBRARIES_ERROR,
  GET_STANDARD_BLOCKS_REQUEST,
  GET_STANDARD_BLOCKS_SUCCESS,
  GET_STANDARD_BLOCKS_ERROR,
  ADD_STANDARD_LIBRARY,
  UPDATE_STANDARD_LIBRARY,
  DELETE_STANDARD_LIBRARY,
  ADD_STANDARD_BLOCK,
  UPDATE_STANDARD_BLOCK,
  DELETE_STANDARD_BLOCK
} from "../types";

const initialState = {
  libraries: [],
  blocks: [],
  isLibrariesFetching: false,
  isBlocksFetching: false,
  error: "",
};

// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_STANDARD_LIBRARIES_REQUEST:
      return {
        ...state,
        isLibrariesFetching: true,
        error: "",
      };
    case GET_STANDARD_LIBRARIES_SUCCESS:
      return {
        ...state,
        libraries: payload || [],
        isLibrariesFetching: false,
      };
    case GET_STANDARD_LIBRARIES_ERROR:
      return {
        ...state,
        isLibrariesFetching: false,
        error: payload,
      };
    case GET_STANDARD_BLOCKS_REQUEST:
      return {
        ...state,
        isBlocksFetching: true,
        error: "",
      };
    case GET_STANDARD_BLOCKS_SUCCESS:
      return {
        ...state,
        blocks: payload || [],
        isBlocksFetching: false,
      };
    case GET_STANDARD_BLOCKS_ERROR:
      return {
        ...state,
        isBlocksFetching: false,
        error: payload,
      };
    case ADD_STANDARD_LIBRARY:
      return {
        ...state,
        libraries: [ 
          payload, 
          ...state.libraries.filter(c => c.id) 
        ]
      };
    case UPDATE_STANDARD_LIBRARY: 
      return {
        ...state,
        libraries: state.libraries
          .map(library => {
            if (library.id === payload.id) return payload;
            return ;
          }),
      };
    case DELETE_STANDARD_LIBRARY:
      return {
        ...state,
        libraries: state.libraries
          .filter(library => library.id !== payload),
      };
    case ADD_STANDARD_BLOCK:
      return {
        ...state,
        blocks: [ 
          payload, 
          ...state.blocks.filter(b => b.id) 
        ]
      };
    case UPDATE_STANDARD_BLOCK: 
      const updatedBlockId = state.blocks.find(b => b.id === payload.id) || null;
      return {
        ...state,
        blocks: state.blocks
          .map(block => {
            if (block.id === updatedBlockId) return payload;
            return block;
          }),
      };
    case DELETE_STANDARD_BLOCK:
      return {
        ...state,
        blocks: state.blocks
          .filter(block => block.id !== payload),
      };
    default:
      return state;
  }
}
