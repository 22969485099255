import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Buffer = require('buffer/').Buffer;

const RedirectGuard = props => {
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("beforeunload", saveCurrentPage);
    return () => window.removeEventListener("beforeunload", saveCurrentPage);
  }, []);

  const saveCurrentPage = () => {
    const encodedPage = Buffer
      .from(document.location.pathname, 'utf-8')
      .toString('base64');
    sessionStorage.setItem("currentPage", encodedPage);
  }

  useEffect(() => {
    const previousPage = sessionStorage.getItem("currentPage");
    let redirectToMain = localStorage.getItem("redirectToMainOnError");
    redirectToMain = JSON.parse(redirectToMain);
    let decodedPage;

    if (!previousPage) return;

    if (redirectToMain?.count === 2) {
      decodedPage = "/office/global";
      localStorage.removeItem("redirectToMainOnError");
    } else {
      decodedPage = Buffer
        .from(previousPage, 'base64')
        .toString('utf8');
    }

    if (document.location.pathname !== decodedPage) {
      navigate(decodedPage, { replace: true });
    }
    
    sessionStorage.removeItem("currentPage");
  }, [navigate]);

  return (
    <React.Fragment>
        {props.children}
    </React.Fragment>
  )
};

export default RedirectGuard;