import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";
import {
  GET_STRUCTURE,
  GET_STRUCTURES,
  DELETE_STRUCTURE,
  STRUCTURE_LOADER,
  IMPORT_CSV_STRUCTURE_REQUEST,
  IMPORT_CSV_STRUCTURE_SUCCESS,
  IMPORT_CSV_STRUCTURE_ERROR,
  GET_LIBRARIES_REQUEST,
  GET_LIBRARIES_SUCCESS,
  GET_LIBRARIES_ERROR,
  CREATE_LIBRARY_REQUEST,
  CREATE_LIBRARY_SUCCESS,
  CREATE_LIBRARY_ERROR,
  EDIT_LIBRARY_REQUEST,
  EDIT_LIBRARY_SUCCESS,
  EDIT_LIBRARY_ERROR,
  DUPLICATE_LIBRARY_REQUEST,
  DUPLICATE_LIBRARY_SUCCESS,
  DUPLICATE_LIBRARY_ERROR,
  DELETE_LIBRARY_REQUEST,
  DELETE_LIBRARY_SUCCESS,
  DELETE_LIBRARY_ERROR,
  GET_STRUCTURES_REQUEST,
  GET_STRUCTURES_SUCCESS,
  GET_STRUCTURES_ERROR,
  CREATE_STRUCTURES_REQUEST,
  CREATE_STRUCTURES_SUCCESS,
  CREATE_STRUCTURES_ERROR,
  UPDATE_STRUCTURE_SUCCESS,
  GET_LIBRARIES_WITH_CAPEX_REQUEST,
  GET_LIBRARIES_WITH_CAPEX_SUCCESS,
  GET_LIBRARIES_WITH_CAPEX_ERROR,
  DUPLICATE_STRUCTURE_REQUEST,
  DUPLICATE_STRUCTURE_SUCCESS,
  DUPLICATE_STRUCTURE_ERROR
} from "../types";

const initialState = {
  libraries: [],
  librariesWithCapex: [],
  structures: [],
  structuresImported: [],
  structure: {},
  loading: false,
  isUpdating: false,
  error: {},
  errorMessage: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STRUCTURES:
      return {
        ...state,
        structures: action.payload,
      };
    case GET_STRUCTURES_REQUEST:
      return {
        ...state,
        loading: true,
        structures: [],
      };
    case GET_STRUCTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        structures: action.payload,
      };
    case GET_STRUCTURES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LIBRARIES_REQUEST:
      return {
        ...state,
        loading: true,
        libraries: [],
      };
    case GET_LIBRARIES_SUCCESS:
      return {
        ...state,
        loading: false,
        libraries: action.payload,
      };
    case GET_LIBRARIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_LIBRARY_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case CREATE_LIBRARY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        libraries: [...state.libraries, action.payload]
      };
    case CREATE_LIBRARY_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case EDIT_LIBRARY_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case EDIT_LIBRARY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        libraries: updateItemInArray(state.libraries, action.payload)
      };
    case EDIT_LIBRARY_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case DUPLICATE_LIBRARY_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case DUPLICATE_LIBRARY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        libraries: [...state.libraries, action.payload]
      };
    case DUPLICATE_LIBRARY_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case DELETE_LIBRARY_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case DELETE_LIBRARY_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        libraries: state.libraries.filter((library) => library.id !== action.payload),
      };
    case DELETE_LIBRARY_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };
    case GET_LIBRARIES_WITH_CAPEX_REQUEST:
      return {
        ...state,
        loading: true,
        librariesWithCapex: [],
      };
    case GET_LIBRARIES_WITH_CAPEX_SUCCESS:
      return {
        ...state,
        loading: false,
        librariesWithCapex: action.payload,
      };
    case GET_LIBRARIES_WITH_CAPEX_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_STRUCTURES_REQUEST:
      return {
        ...state,
        isUpdating: true,
        structuresImported: [],
      };
    case CREATE_STRUCTURES_SUCCESS:
      // const tmpStructuresImported = [...state.structuresImported, action.payload];
      return {
        ...state,
        isUpdating: false,
        // structuresImported: tmpStructuresImported,
      };
    case CREATE_STRUCTURES_ERROR:
      return {
        ...state,
        isUpdating: true,
        error: action.payload,
      };
    case UPDATE_STRUCTURE_SUCCESS: 
      return {
        ...state,
        structures: updateItemInArray(state.structures, action.payload)
      }
    case IMPORT_CSV_STRUCTURE_REQUEST:
      return {
        ...state,
        loading: true,
        structures: [],
      };
    case IMPORT_CSV_STRUCTURE_SUCCESS:
      let tmpStructures = state.structures;
      tmpStructures = action.payload.concat(tmpStructures);
      return {
        ...state,
        structures: tmpStructures,
        loading: false,
      };
    case IMPORT_CSV_STRUCTURE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_STRUCTURE:
      return {
        ...state,
        structure: action.payload,
      };
    case DUPLICATE_STRUCTURE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case DUPLICATE_STRUCTURE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        structures: [...state.structures, action.payload]
      };
    case DUPLICATE_STRUCTURE_ERROR:
      return {
        ...state,
        isUpdating: false,
      };
    case STRUCTURE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    case DELETE_STRUCTURE:
      return {
        ...state,
        structures: state.structures.filter(
          (structure) => structure.id !== +action.payload
        ),
      };
    default:
      return state;
  }
}
