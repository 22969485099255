import React from "react";
import ForgotPasswordForm from "../../../containers/user-management/ForgotPasswordForm/ForgotPasswordForm";
import { Wrapper, Container, Content, EntryCol, ContentCol, Logo, SubLogoTitle } from '../styles';

const ForgotPasswordPage = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
            <EntryCol>
              <Logo>Offolio</Logo>
              <SubLogoTitle>Login to access your account</SubLogoTitle>
            </EntryCol>
            <ContentCol>
              <ForgotPasswordForm />
            </ContentCol>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ForgotPasswordPage;
