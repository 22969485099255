import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background: #f6f6ef;

  @media screen and (min-width: 1024px) {
    height: 100vh;
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 4rem 0;
  box-shadow: 0 0 15px 0px rgb(1 1 1 / 16%);
`;

export const Content = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: 0 1rem;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const ContentCol = styled.div`
  flex: 1;
  justify-content: center;
`;

export const EntryCol = styled(ContentCol)`
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export const Logo = styled.div`
  font-weight: bold;
  font-size: 4rem;
  line-height: 1;
  text-transform: uppercase;
  color: #491777;
  margin-bottom: 1rem;
`;

export const SubLogoTitle = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
`;