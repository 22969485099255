import { updateItemInArray } from "../../utils/FilterUtils/FilterUtils";
import {
  GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_ERROR,
  GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_SUCCESS,
  GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_REQUEST,
  GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_REQUEST,
  GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_SUCCESS,
  GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_ERROR,
  GET_PORTFOLIO_CALENDARS_REQUEST,
  GET_PORTFOLIO_CALENDARS_SUCCESS,
  GET_PORTFOLIO_CALENDARS_ERROR,
  UPDATE_PORTFOLIO_CALENDAR_REQUEST,
  UPDATE_PORTFOLIO_CALENDAR_SUCCESS,
  UPDATE_PORTFOLIO_CALENDAR_ERROR
} from "../types";
import produce from "immer";

const initialState = {
    calendars: [],
    workWeek: [],
    isFetching: false,
    isUpdating: false
  };

export default function(state = initialState, action ) {
    switch (action.type) {
        case GET_PORTFOLIO_CALENDARS_REQUEST:
            return {
                ...state,
                isFetching : true,
            };
        case GET_PORTFOLIO_CALENDARS_SUCCESS:
            return {
                ...state,
                calendars: action.payload,
                isFetching : false,
            };
        case GET_PORTFOLIO_CALENDARS_ERROR:
            return {
                ...state,
                isFetching : false,
                error: action.payload,
            };
        case  GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_REQUEST:
            return {
              ...state,
              isFetching : true,
              workWeek: [],
              error: "",
            };
        case GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_SUCCESS:
            return {
              ...state,
              workWeek: action.payload,
              isFetching : false,
            };
        case GET_WORKABLE_DAYSNAME_PORTFOLIO_CALENDARS_ERROR:
            return {
              ...state,
              isFetching : false,
              error: action.payload,
            };
        case  GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_REQUEST:
            return {
                ...state,
                isFetching : true,
                workWeek: [],
                error: "",
            };
        case GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_SUCCESS:
            return {
                ...state,
                workWeek: action.payload,
                isFetching : false,
            };
        case GET_WORKABLE_DAYSNAME_RESOURCES_CALENDARS_ERROR:
            return {
                ...state,
                isFetching : false,
                error: action.payload,
            };
        case UPDATE_PORTFOLIO_CALENDAR_REQUEST: 
            return produce(state, (draftState) => {
                draftState.isUpdating = true;
            });
        case UPDATE_PORTFOLIO_CALENDAR_SUCCESS:
            return produce(state, (draftState) => {
                draftState.isUpdating = false;
                draftState.calendars = updateItemInArray(draftState.calendars, action.payload);
            });
        case UPDATE_PORTFOLIO_CALENDAR_ERROR:
            return produce(state, (draftState) => {
                draftState.isUpdating = false;
                draftState.error = action.payload;
            });
        default:
            return state;
    }
}