import React from 'react';
import { TailSpin } from "react-loader-spinner";
import './tailSpinLoader.css';

const TailSpinLoader = () => {
    return (
        <div className="loader-container">
            <div className="spinner-container">
                <TailSpin
                    type="TailSpin"
                    color="#8f00ff"
                    height={100}
                    width={100}
                />
            </div>
            <div className="loading-text">Loading...</div>
        </div>
    );
};

export default TailSpinLoader;
