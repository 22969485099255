import {
  SCENARIO_BLOCK,
  LIBRARY_BLOCK,
  SCENARIO_ACTIVITY,
  LIBRARY_ACTIVITY,
  SCENARIO_GROUP,
  LIBRARY_GROUP,
  LIBRARY_CATEGORY,
  LIBRARY_STRUCTURE,
  MAN_HOURS,
} from "../../global/constants";

export const sortNodes = (nodes) => {
  const getTypePriority = (type) => {
    switch (type) {
      case LIBRARY_CATEGORY:
        return 1;
      case LIBRARY_STRUCTURE:
        return 2;
      case SCENARIO_GROUP:
      case LIBRARY_GROUP:
        return 3;
      case SCENARIO_BLOCK:
      case LIBRARY_BLOCK:
        return 4;
      case SCENARIO_ACTIVITY:
      case LIBRARY_ACTIVITY:
        return 5;
      default:
        return 6;
    }
  };

  return nodes
    .sort((a, b) => a.data.label.localeCompare(b.data.label))
    .sort((a, b) => getTypePriority(a.type) - getTypePriority(b.type));
};

export function mapUsagesAndDimensions(usagesData) {
  let usages = [];
  let dimensions = [];

  if (
    typeof usagesData === "object" &&
    !Array.isArray(usagesData) &&
    usagesData !== null
  ) {
    dimensions = Object.keys(usagesData).map((item) => {
      const propertyName = item.toLowerCase().replace(" ", "") + "Work";
      return {
        name: item === MAN_HOURS ? "work" : propertyName,
        caption: item,
      };
    });

    const usageDimensions = {};
    dimensions.forEach((d) => (usageDimensions[d.name] = 0));

    dimensions.forEach((i) => {
      usagesData[i.caption].forEach(
        ({ usage, fte, projectStatus, tagName }) => {
          usages.push({
            ...usage,
            fte,
            projectStatus,
            tagName: tagName || "None",
            timeMs: new Date(usage.date).getTime(),
            ...usageDimensions,
            [i.name]: usage.work,
          });
        }
      );
    });
  } else {
    if (usagesData[0]?.hasOwnProperty("usage")) {
      usages = usagesData.map(({ usage, fte, projectStatus, tagName }) => {
        return {
          ...usage,
          fte,
          projectStatus,
          tagName: tagName || "None",
          timeMs: new Date(usage.date).getTime(),
        };
      });
    } else {
      usages = usagesData.map((usage) => ({
        ...usage,
        timeMs: new Date(usage.date).getTime(),
      }));
    }
    populateDimensionsWhenUsageDataIsAnArray(usagesData, usages, dimensions);
  }
  return { usages, dimensions };
}

function populateDimensionsWhenUsageDataIsAnArray(
  usagesData,
  usages,
  dimensions
) {
  const dimensionsResourceNames = usagesData.reduce((resourceNames, usage) => {
    if (
      !usage.dimensionResource ||
      resourceNames.includes(usage.dimensionResource)
    )
      return resourceNames;
    resourceNames.push(usage.dimensionResource);
    return resourceNames;
  }, []);
  usages.forEach((usage) => {
    const usageIsDimensioning = dimensionsResourceNames.includes(
      usage.dimensionResource
    );
    dimensionsResourceNames.forEach((dimension) => {
      if (dimension === usage.dimensionResource) usage[dimension] = usage.work;
      else usage[dimension] = undefined;
      usage.isDimensioning = usageIsDimensioning;
    });
  });
  dimensionsResourceNames.forEach((caption) => {
    dimensions.push({
      name: caption,
      caption,
      title: caption,
    });
  });
}
