import {
  AI_TEST_REQUEST,
  AI_TEST_SUCCESS,
  AI_TEST_ERROR,
  AI_TEST_CLEAR
} from "../types";
  
const initialState = {
  data: undefined,
  isLoading: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case AI_TEST_REQUEST: {
      return {
        ...state, 
        data: undefined,
        isLoading: true
      }
    }
    case AI_TEST_SUCCESS: {
      return {
        ...state, 
        data: payload,
        isLoading: false
      }
    }
    case AI_TEST_ERROR: {
      return {
        ...state, 
        isLoading: false
      }
    }
    case AI_TEST_CLEAR: {
      return {
        ...state, 
        data: undefined,
        isLoading: false
      }
    }
    default:
      return state;
  }
}