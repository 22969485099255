import { sum } from "@syncfusion/ej2-react-charts";
import {
  roundToDecimalPlace,
  safeDivision,
} from "../../utils/ArithmeticUtils/ArithmeticUtils";
import {
  conditionalCompute,
  multiply,
  subtractWithoutResidue,
} from "../../utils/MathUtils/MathUtils";
import {
  GET_ACTUALS_ERROR,
  GET_ACTUALS_REQUEST,
  GET_ACTUALS_SUCCESS,
  GET_PORTFOLIO_USAGE_REQUEST,
  GET_PORTFOLIO_USAGE_SUCCESS,
  GET_PORTFOLIO_USAGE_ERROR,
  IMPORT_ACTUALS_ERROR,
  IMPORT_ACTUALS_REQUEST,
  IMPORT_ACTUALS_SUCCESS,
  UPDATE_USAGE_REQUEST,
  UPDATE_USAGE_SUCESS,
  GET_DYNAMIC_COLUMNS_REQUEST,
  GET_DYNAMIC_COLUMNS_SUCCESS,
  GET_DYNAMIC_COLUMNS_ERROR,
  CLEAR_USAGES,
  UPDATE_USAGES_REQUEST,
  UPDATE_USAGES_SUCESS,
  UPDATE_USAGE_ERROR,
  UPDATE_USAGES_ERROR,
  GET_AVAILABILITY_REQUEST,
  GET_AVAILABILITY_SUCCESS,
  GET_AVAILABILITY_ERROR,
  UPDATE_DYNAMIC_USAGES_SUCCESS,
  GET_USAGE_BY_RESOURCE_REQUEST,
  GET_USAGE_BY_RESOURCE_SUCCESS,
  GET_USAGE_BY_RESOURCE_ERROR,
  GET_TEAMS_WORKLOAD_REQUEST,
  GET_TEAMS_WORKLOAD_SUCCESS,
  GET_TEAMS_WORKLOAD_ERROR,
  UPDATE_TEAMS_WORKLOAD,
  UPDATE_WORKLOAD_ON_PROJECTBUCKETS_REQUEST,
  UPDATE_WORKLOAD_ON_PROJECTBUCKETS_SUCCESS,
  UPDATE_WORKLOAD_ON_PROJECTBUCKETS_ERROR,
  SET_UPLOAD_PROGRESS,
  GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_REQUEST,
  GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_SUCCESS,
  GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_ERROR,
  COPY_AT_COMPLETION_SUCCESS,
  COPY_AT_COMPLETION_REQUEST,
  COPY_AT_COMPLETION_ERROR,
} from "../types";

import produce from "immer";
import { computeBudgetAtCompletion } from "../../utils/WorkloadUtils/WorkloadUtils";
import { MAN_HOURS } from "../../global/constants";
import { mapUsagesAndDimensions } from "../../utils/MappingUtils/MappingUtils";

const initialState = {
  usages: [],
  dimensions: [],
  availability: [],
  teamsWorkload: [],
  usage: {},
  columns: [],
  isUpdating: false,
  isFetching: false,
  isAvailabilityFetching: false,
  isImporting: false,
  error: "",
  uploadProgress: "",
};

// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_USAGE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_USAGE_SUCESS:
      return {
        ...state,
        usages: state.usages.map((item) => {
          if (item.id === payload.usage.id) {
            const { usage, fte, projectStatus } = payload;
            return { ...usage, fte, projectStatus };
          }
          return item;
        }),
        isUpdating: false,
      };
    case UPDATE_USAGE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case UPDATE_USAGES_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_USAGES_SUCESS:
      return {
        ...state,
        usages: state.usages.map((item) => {
          const currentUsage = payload.find((p) => p.usage.id === item.id);
          if (!currentUsage) return item;
          const { fte, usage, projectStatus } = currentUsage;
          return { ...usage, fte, projectStatus };
        }),
        isUpdating: false,
      };
    case UPDATE_DYNAMIC_USAGES_SUCCESS:
      return {
        ...state,
        usages: state.usages.map(
          (item) =>
            payload.find(
              ({ scheduleActivityId }) =>
                scheduleActivityId === item.scheduleActivityId
            ) || item
        ),
        isUpdating: false,
      };
    case UPDATE_USAGES_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case GET_PORTFOLIO_USAGE_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.usages = [];
        draftState.dimensions = [];
        draftState.error = "";
      });
    case GET_PORTFOLIO_USAGE_SUCCESS:
      const { usages, dimensions } = mapUsagesAndDimensions(payload);

      return produce(state, (draftState) => {
        draftState.usages = usages;
        draftState.dimensions = dimensions;
        draftState.isFetching = false;
      });
    case GET_PORTFOLIO_USAGE_ERROR:
      return produce(state, (draftState) => {
        draftState.error = payload;
        draftState.isFetching = false;
      });
    case GET_ACTUALS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_ACTUALS_SUCCESS:
      return {
        ...state,
        usages: payload,
        isFetching: false,
      };
    case GET_ACTUALS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_DYNAMIC_COLUMNS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_DYNAMIC_COLUMNS_SUCCESS:
      return {
        ...state,
        columns: payload,
        isFetching: false,
      };
    case GET_DYNAMIC_COLUMNS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.columns = [];
        draftState.usages = [];
      });
    case GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.columns = payload.columns || [];
        draftState.usages = payload.usages || [];
      });
    case GET_DYNAMIC_COLUMNS_AND_USAGES_OF_YEAR_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
      });
    case GET_AVAILABILITY_REQUEST:
      return produce(state, (draftState) => {
        draftState.isAvailabilityFetching = true;
        draftState.availability = [];
        draftState.error = "";
      });
    case GET_AVAILABILITY_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isAvailabilityFetching = false;
        draftState.availability = payload;
      });
    case GET_AVAILABILITY_ERROR:
      return produce(state, (draftState) => {
        draftState.isAvailabilityFetching = false;
        draftState.error = payload;
      });
    case IMPORT_ACTUALS_REQUEST:
      return {
        ...state,
        isImporting: true,
        error: "",
      };
    case IMPORT_ACTUALS_SUCCESS:
      return {
        ...state,
        usages: payload,
        isImporting: false,
      };
    case IMPORT_ACTUALS_ERROR:
      return {
        ...state,
        isImporting: false,
        error: payload,
      };
    case CLEAR_USAGES:
      return {
        ...state,
        usages: [],
      };
    case GET_USAGE_BY_RESOURCE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USAGE_BY_RESOURCE_SUCCESS:
      return {
        ...state,
        usages: payload.map(({ usage, fte }) => ({ ...usage, fte })),
        isFetching: false,
      };
    case GET_USAGE_BY_RESOURCE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_TEAMS_WORKLOAD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case COPY_AT_COMPLETION_REQUEST:
      return produce(state, (draftState) => {
        draftState.isUpdating = true;
      });
    case COPY_AT_COMPLETION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.teamsWorkload = draftState.teamsWorkload.map((workload) => {
          const budgetAtCompletionValue = computeBudgetAtCompletion(workload);
          const currentAtCompletion =
            budgetAtCompletionValue + workload.actuals;
          workload.previousAtCompletion = currentAtCompletion;
          return workload;
        });
      });
    case COPY_AT_COMPLETION_ERROR:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.error = payload;
      });
    case GET_TEAMS_WORKLOAD_SUCCESS:
      const teamsWorkloadWithFTE = payload.map((item) => {
        const budgetAtCompletionValue = computeBudgetAtCompletion(item);
        const budgetAtCompletion = budgetAtCompletionValue + item.actuals;
        const rawBudgetAtCompletionFTE =
          safeDivision(budgetAtCompletionValue, item.yearlyFTE) +
          safeDivision(item.actuals, item.yearlyFTE);
        const rawActualVarianceFTE =
          rawBudgetAtCompletionFTE -
          safeDivision(item.previousForecast, item.yearlyFTE);

        return {
          ...item,
          forecast: roundToDecimalPlace(item.forecast, 2),
          budgetAtCompletion,
          budgetAtCompletionFTE: roundToDecimalPlace(
            rawBudgetAtCompletionFTE,
            1
          ),
          actualVariance: subtractWithoutResidue(
            item.previousAtCompletion,
            budgetAtCompletion
          ),
          actualVarianceFTE: roundToDecimalPlace(-rawActualVarianceFTE, 1),
        };
      });

      return {
        ...state,
        teamsWorkload: teamsWorkloadWithFTE,
        isFetching: false,
        error: null,
      };
    case GET_TEAMS_WORKLOAD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case UPDATE_TEAMS_WORKLOAD:
      const { shouldReRender, data } = payload;
      if (shouldReRender)
        return produce(state, (draftState) => {
          const index = state.teamsWorkload.findIndex((item) => {
            return (
              item.projectName === data.projectName &&
              item.resourceId === data.resourceId &&
              item.year === data.year
            );
          });

          draftState.teamsWorkload[index] = data;
        });

      for (let i = 0; i < state.teamsWorkload.length; i++) {
        const item = state.teamsWorkload[i];
        if (
          item.projectName === data.projectName &&
          item.resourceId === data.resourceId &&
          item.year === data.year
        ) {
          item[i] = data;
          break;
        }
      }
      return state;
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: payload,
      };
    /*case UPDATE_WORKLOAD_ON_PROJECTBUCKETS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_WORKLOAD_ON_PROJECTBUCKETS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_WORKLOAD_ON_PROJECTBUCKETS_ERROR:
      return {
        ...state,
        isFetching: false,
      }; */
    default:
      return state;
  }
}
