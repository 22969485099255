import {
    GET_FONT_SUCCESS,
    GET_FONT_REQUEST,
    GET_FONT_ERROR,
    DELETE_FONT_REQUEST,
    DELETE_FONT_SUCCESS,
    DELETE_FONT_ERROR,
    CREATE_FONT_REQUEST,
    CREATE_FONT_SUCCESS,
    CREATE_FONT_ERROR,
    UPDATE_FONT_REQUEST, UPDATE_FONT_SUCCESS, UPDATE_FONT_ERROR,
} from "../types";

const initialState = {
    fonts: [],
    isFetching: false,
    error: "",
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_FONT_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: "",
            };
        case GET_FONT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fonts: payload || [],
            };
        case GET_FONT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: payload,
            };

        case CREATE_FONT_REQUEST:
            return {
                ...state,
                error: "",
            };
        case CREATE_FONT_SUCCESS:
            return {
                ...state,
                fonts: [...state.fonts, payload],
            };
        case CREATE_FONT_ERROR:
            return {
                ...state,
                error: payload,
            };

        case DELETE_FONT_REQUEST:
            return {
                ...state,
                error: "",
            };
        case DELETE_FONT_SUCCESS:
            return {
                ...state,
                fonts: state.fonts.filter((font) => font.id !== payload),
            };
        case DELETE_FONT_ERROR:
            return {
                ...state,
                error: payload,
            };

        case UPDATE_FONT_REQUEST:
            return {
                ...state,
                error: "",
            };
        case UPDATE_FONT_SUCCESS:
            return {
                ...state,
                fonts: state.fonts.map((font) => {
                    if (font.id === payload.id) {
                        return {...font, ...payload};
                    }
                    return font;
                }),
            };
        case UPDATE_FONT_ERROR:
            return {
                ...state,
                error: payload,
            };

        default:
            return state;
    }
};
