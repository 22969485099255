import {
  TAG_CREATE_REQUEST,
  TAG_CREATE_SUCCESS,
  TAG_CREATE_ERROR,
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR,
  TAG_DELETE_REQUEST,
  TAG_DELETE_SUCCESS,
  TAG_DELETE_ERROR,
  TAG_UPDATE_REQUEST,
  TAG_UPDATE_SUCCESS,
  TAG_UPDATE_ERROR,
  GET_TAGS_BY_STRUCTURE_REQUEST,
  GET_TAGS_BY_STRUCTURE_SUCCESS,
  GET_TAGS_BY_STRUCTURE_ERROR,
  CLEAR_TAGS_FOR_STRUCTURE
} from "../types";

const initialState = {
  tags: [],
  tagsForStructure: [],
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  error: "",
};

// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TAG_CREATE_REQUEST:
      return {
        ...state,
        isCreating: true,
        error: "",
      };
    case TAG_CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        tags: [...state.tags, payload],
      };
    case TAG_CREATE_ERROR:
      return {
        ...state,
        isCreating: false,
        error: payload,
      };
    case GET_TAGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: (payload || []).sort((a, b) => a.name.localeCompare(b.name)),
        isFetching: false,
      };
    case GET_TAGS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case TAG_DELETE_REQUEST:
      return {
        ...state,
        error: "",
      };
    case TAG_DELETE_SUCCESS:
      return {
        ...state,
        tags: state.tags.filter((tag) => tag.id !== payload),
      };
    case TAG_DELETE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case TAG_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case TAG_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        tags: state.tags.map((tag) => {
          if (tag.id === payload.id) {
            return { ...tag, ...payload };
          }
          return tag;
        }),
      };
    case TAG_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case GET_TAGS_BY_STRUCTURE_REQUEST:
      return {
        ...state,
        isFetching: true,
        tagsForStructure: [],
        error: "",
      };
    case GET_TAGS_BY_STRUCTURE_SUCCESS:
      return {
        ...state,
        tagsForStructure: payload || [],
        isFetching: false,
      };
    case GET_TAGS_BY_STRUCTURE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case CLEAR_TAGS_FOR_STRUCTURE:
      return {
        ...state,
        tagsForStructure: [],
      };
    default:
      return state;
  }
};
