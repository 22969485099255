import {
  CUSTOM_SUPPLY_YEAR_FILTER,
  CUSTOM_TIMELINE_YEAR_FILTER,
  DEFAULT_FINISH_OF_DAY,
  DEFAULT_START_OF_DAY,
  FULL_TIMELINE_YEAR_FILTER,
  HOURS_IN_DAY, MINUTES_IN_HOUR,
  PORTFOLIO_DATE_FILTER,
  SCHEDULER_FILTER_DATES,
  USER_TASKBOARD_DATE_FILTER,
} from "../../global/constants";
import { parseISO } from 'date-fns';


export const getMonthFromNumber = (number) => {
  let maxMonth = 12,
    minMonth = 1,
    newNumber = 1;
  if (number > maxMonth) {
    newNumber = number - maxMonth;
  } else if (number < minMonth) {
    newNumber = maxMonth + number;
  } else {
    newNumber = number;
  }
  return newNumber;
};

export const formatDateRangePickerDate = (str) => {
  let formatedStr = str.replace(" ", "T");
  const hours = parseInt(formatedStr.substring(11, 13));

  if (formatedStr.includes("P")) {
    formatedStr = formatedStr.replace(/..:/, `${hours === 12 ? hours : (hours + 12) % 24}:`);
  } else if (formatedStr.includes("A") && hours === 12) {
    formatedStr = formatedStr.replace(/..:/, "00:");
  }

  formatedStr = formatedStr.replace(/ (A|P)M/, ":00");
  return new Date(formatedStr);
};

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getStartOfWeek(date) {
  return subtractDay(date, date.getDay());
}

export function getLastYear() {
  const start = new Date();
  start.setFullYear(start.getFullYear() - 1);
  start.setMonth(0);
  start.setDate(1);
  return {
    start: formatDate(start),
    finish: formatDate(new Date()),
  };
}

export function getNextYear() {
  const finish = new Date();
  finish.setFullYear(finish.getFullYear() + 2);
  finish.setMonth(0);
  finish.setDate(0);
  return {
    start: formatDate(new Date()),
    finish: formatDate(finish),
  };
}

export function addTwelveMonths(startDate) {
  const start = new Date(startDate);
  const newEnd = new Date(startDate);
  newEnd.setMonth(newEnd.getMonth() + 12);
  return {
    start: formatDate(start),
    finish: formatDate(newEnd),
  };
}

export function removeTimezoneOffset(date) {
  const _date = new Date(date);
  const timeZoneOffset = _date.getTimezoneOffset();
  return timeZoneOffset != 0
    ? new Date(_date.setMinutes(_date.getMinutes() - timeZoneOffset))
    : date;
}

export function getCurrentYear() {
  const start = new Date();
  const finish = new Date();
  start.setMonth(0);
  start.setDate(1);
  finish.setFullYear(finish.getFullYear() + 1);
  finish.setMonth(0);
  finish.setDate(0);
  return {
    start: formatDate(start),
    finish: formatDate(finish),
  };
}

export const getDefaultView = () => ({
  start: formatDate(removeMonthsFromCurrentDate(3)),
  finish: formatDate(addMonthsToCurrentDate(6)),
});

export const addMonthsToCurrentDate = (months) => {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  return date;
};

export const removeMonthsFromCurrentDate = (months) => {
  const date = new Date();
  date.setMonth(date.getMonth() - months);
  return date;
};

export const getEarliestStartDate = (array) =>
  array.reduce((earliestDate, { startDate }) => {
    const currentStartDate = new Date(startDate);
    return currentStartDate < earliestDate ? currentStartDate : earliestDate;
  }, new Date());

export const getLatestFinishDate = (array) =>
  array.reduce((latestDate, { finishDate }) => {
    const currentFinishDate = new Date(finishDate);
    return currentFinishDate > latestDate ? currentFinishDate : latestDate;
  }, new Date());

export function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function removeDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function addWeeksFromCurrentDate(weeks) {
  let result = new Date();
  result.setDate(result.getDate() + weeks * 7);
  return result;
}

export function removeWeeksFromCurrentDate(weeks) {
  let result = new Date();
  result.setDate(result.getDate() - weeks * 7);
  return result;
}

export function getFirstDateOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDateOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getWeekNumber(date) {
  // Copy date so don't modify original
  var d = new Date(date);
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

// export function calculateEndDate(startDate, interval = 0) {
//   if (!+interval) return;
//
//   const isMonth = allowFeature(MONTH_ON_METRIC);
//   const endDate = new Date(startDate);
//
//   if (isMonth) {
//     endDate.setMonth(endDate.getMonth() + +interval);
//   } else {
//     endDate.setDate(endDate.getDate() + +interval * 7);
//   }
//
//   return endDate.toDateString();
// }

export const today = new Date();

const weekAccronyms = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const weekendAccronyms = ["Sat", "Sun"];

export function formatCalendars(calendars) {
  return calendars.map(
    ({ id, name, firstDate, lastDate, daysOfWeek, workDays }) => ({
      id,
      name,
      startDate: firstDate,
      endDate: lastDate,
      intervals: generateIntervalsFromCalendar(daysOfWeek, workDays),
      unspecifiedTimeIsWorking: false,
    })
  );
}

function generateIntervalsFromCalendar(daysOfWeek, workDays) {
  const intervals = [];
  daysOfWeek.forEach(addWorkingDayInterval, intervals);
  workDays.forEach(addDaysOfInterval, intervals);
  return intervals;
}

function addWorkingDayInterval(dayOfWeek) {
  const dayAcronym = dayOfWeek.slice(0, 3).toUpperCase();

  if (!dayAcronym.trim()) return null;

  this.push({
    recurrentStartDate: `On ${dayAcronym} at 9:00`,
    recurrentEndDate: `On ${dayAcronym} at 16:00`,
    isWorking: true,
  });
}

export function formatCalendarsBasedOnShifts(calendars) {
  return calendars.map(
    ({ id, name, firstDate, lastDate, daysOfWeek, workDays, shifts }) => ({
      id,
      name,
      startDate: firstDate,
      endDate: lastDate,
      intervals: generateIntervalsFromCalendarBasedOnShifts(
        daysOfWeek,
        workDays,
        shifts
      ),
      unspecifiedTimeIsWorking: false,
    })
  );
}

function generateIntervalsFromCalendarBasedOnShifts(
  daysOfWeek,
  workDays,
  shifts
) {
  const intervals = [];
  daysOfWeek.forEach((day) =>
    addWorkingDayIntervalBasedOnShifts(day, shifts, intervals)
  );
  workDays.forEach(addDaysOfInterval, intervals);
  return intervals;
}

function addWorkingDayIntervalBasedOnShifts(dayOfWeek, shifts, intervals) {
  const dayAcronym = dayOfWeek.slice(0, 3).toUpperCase();
  if (!dayAcronym.trim()) return null;

  const mergedShifts = mergeShifts(shifts);
  createIntervalsBasedOnShifts(mergedShifts, dayAcronym, intervals);
}

function createIntervalsBasedOnShifts(mergedShifts, day, intervals) {
  return mergedShifts.forEach((interval) => {
    let endDay = day;
    if (interval.start > interval.end) {
      endDay = getNextDay(day);
    }

    intervals.push({
      recurrentStartDate: `On ${day} at ${interval.start.slice(0, -3)}`,
      recurrentEndDate: `On ${endDay} at ${interval.end.slice(0, -3)}`,
      isWorking: true,
    });
  });
}

function getNextDay(day) {
  const daysOrder = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const currentIndex = daysOrder.indexOf(day);

  return daysOrder[(currentIndex + 1) % 7];
}

function mergeShifts(shifts) {
  if (!shifts.length) return [];

  let sortedShifts = shifts
    .slice()
    .sort((a, b) => a.startHour.localeCompare(b.startHour));

  let mergedIntervals = [
    { start: sortedShifts[0].startHour, end: sortedShifts[0].finishHour },
  ];

  for (let i = 1; i < sortedShifts.length; i++) {
    const currentShift = sortedShifts[i];
    const lastMergedInterval = mergedIntervals[mergedIntervals.length - 1];

    if (currentShift.startHour <= lastMergedInterval.end) {
      const isOverNightShift = currentShift.startHour > currentShift.finishHour;
      lastMergedInterval.end =
        lastMergedInterval.end < currentShift.finishHour || isOverNightShift
          ? currentShift.finishHour
          : lastMergedInterval.end;
    } else {
      mergedIntervals.push({
        start: currentShift.startHour,
        end: currentShift.finishHour,
      });
    }
  }

  return mergedIntervals;
}

export function dailyResourceWorkHoursBasedOnShifts(shifts) {
  const mergedShifts = mergeShifts(shifts);
  let totalHours = 0;

  mergedShifts.forEach(entry => {
    const start = entry.start.split(':');
    const end = entry.end.split(':');

    let startTime = parseInt(start[0]) + parseInt(start[1]) / MINUTES_IN_HOUR;
    let endTime = parseInt(end[0]) + parseInt(end[1]) / MINUTES_IN_HOUR;

    if (endTime < startTime) {
      endTime += HOURS_IN_DAY;
    }

    totalHours += endTime - startTime;
  });

  return totalHours;
}

function addDaysOfInterval(dayOff) {
  if (!dayOff) return;

  const startDate = new Date(dayOff.date);
  const endDate = addDay(startDate, 1);
  if (isNaN(startDate.getTime())) return;

  this.push({
    startDate: formatToYYYYMMDD(startDate),
    endDate: formatToYYYYMMDD(endDate),
    isWorking: dayOff.isWorking,
  });
}

function addDay(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function subtractDay(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

function formatToYYYYMMDD(date) {
  const { year, month, day } = getDateComponents(date);
  return `${year}/${month}/${day}`;
}

function getDateComponents(date) {
  return {
    year: date.getFullYear(),
    month: String(date.getMonth() + 1).padStart(2, "0"),
    day: String(date.getDate()).padStart(2, "0"),
  };
}

function getDaysOffIntervals(daysOff) {
  const intervals = [];

  daysOff.forEach((dayOff) => {
    const dayOffAcronym = dayOff.slice(0, 3).toUpperCase();
    intervals.push({
      recurrentStartDate: `On ${dayOffAcronym} at 0:00`,
      recurrentEndDate: `On ${dayOffAcronym} at 23:59`,
      isWorking: false,
    });
  });

  return intervals;
}

function getDaysOff(fullWeek, workWeek) {
  return fullWeek.filter((day) => !workWeek.includes(day));
}

function getDateIndicator(days, baseAccronyms) {
  const accronyms = days.map(getAccronymOfDay);
  return baseAccronyms.filter((acc) => accronyms.includes(acc)).join(", ");
}

function getAccronymOfDay(day) {
  const firstLetter = day.substring(0, 1);
  const twoOtherLetters = day.substring(1, 3).toLowerCase();
  return `${firstLetter}${twoOtherLetters}`;
}

const workDaysByLocale = {
  "en-US": [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ],
  "fr-FR": [
    "DIMANCHE",
    "LUNDI",
    "MARDI",
    "MERCREDI",
    "JEUDI",
    "VENDREDI",
    "SAMEDI",
  ],
  "es-ES": [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIÉRCOLES",
    "JUEVES",
    "VIERNES",
    "SÁBADO",
  ],
  "de-DE": [
    "SONNTAG",
    "MONTAG",
    "DIENSTAG",
    "MITTWOCH",
    "DONNERSTAG",
    "FREITAG",
    "SAMSTAG",
  ],
  "it-IT": [
    "DOMENICA",
    "LUNEDÌ",
    "MARTEDÌ",
    "MERCOLEDÌ",
    "GIOVEDÌ",
    "VENERDÌ",
    "SABATO",
  ],
  "pt-PT": [
    "DOMINGO",
    "SEGUNDA-FEIRA",
    "TERÇA-FEIRA",
    "QUARTA-FEIRA",
    "QUINTA-FEIRA",
    "SEXTA-FEIRA",
    "SÁBADO",
  ],
  "ru-RU": [
    "ВОСКРЕСЕНЬЕ",
    "ПОНЕДЕЛЬНИК",
    "ВТОРНИК",
    "СРЕДА",
    "ЧЕТВЕРГ",
    "ПЯТНИЦА",
    "СУББОТА",
  ],
  "zh-CN": [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ],
  "ja-JP": [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ],
  "ko-KR": [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ],
  "ar-SA": [
    "الأحد",
    "الاثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ],
  "nl-NL": [
    "ZONDAG",
    "MAANDAG",
    "DINSDAG",
    "WOENSDAG",
    "DONDERDAG",
    "VRIJDAG",
    "ZATERDAG",
  ],
};

function getLocaleFromWorkDays(workDays) {
  for (const locale in workDaysByLocale) {
    const localeWorkDays = workDaysByLocale[locale];
    if (
      workDays.length === localeWorkDays.length &&
      workDays.every((day, index) => day === localeWorkDays[index])
    ) {
      return locale;
    }
  }

  // Default locale if no match is found
  return "en-US";
}

export function getNumberOfWorkingDays(
  startDate,
  finishDate,
  workDays,
  locale = null
) {
  const dayPeriod = 1000 * 3600 * 24;
  const start = new Date(startDate).getTime();
  const finish = new Date(finishDate).getTime();
  let currentDay = start;
  let counter = 0;

  if (!locale) {
    locale = getLocaleFromWorkDays(workDays);
  }

  while (currentDay < finish) {
    const currentDayName = new Date(currentDay)
      .toLocaleDateString(locale, { weekday: "long" })
      .toUpperCase();

    if (workDays.includes(currentDayName)) counter++;
    currentDay += dayPeriod;
  }

  return counter;
}

export const setHours = (date, hours) => {
  const result = new Date(date);

  result.setHours(hours);
  result.setMinutes(0);
  result.setSeconds(0);

  return result;
};

// start and finish are date, not json
export function guardAgainstStartAboveFinish(start, finish) {
  const object = { start, finish };
  if (start > finish) {
    const date = new Date(start);
    date.setDate(date.getDate() + 1);
    object.finish = date;
  }
  return object;
}

/**
 *
 * @returns return current date time as a string
 */
export function getCurrentDateTime() {
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let date = now.getDate();
  let hours = now.getHours();
  let minutes = now.getMinutes();

  // Pad single digit numbers with a leading zero
  month = month < 10 ? "0" + month : month;
  date = date < 10 ? "0" + date : date;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let formattedDateTime =
    year + "-" + month + "-" + date + "_" + hours + "-" + minutes;
  return formattedDateTime;
}

export const groupByYear = (data) => {
  return data.reduce((acc, item) => {
    const year = item.year;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});
};

export const getLastDayOfYear = (dateOfReview) => {
  const date = new Date(dateOfReview);
  const year = date.getFullYear();
  const lastDay = new Date(year + 1, 0, 0);

  const yyyy = lastDay.getFullYear();
  const mm = String(lastDay.getMonth() + 1).padStart(2, "0");
  const dd = String(lastDay.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
};

export const datesAreEquals = (date1, date2) => {
  if (!date1 || !date2) return false;
  const date1JSON = new Date(date1).toJSON().substring(0, 10);
  const date2JSON = new Date(date2).toJSON().substring(0, 10);
  return date1JSON === date2JSON;
};

export const getStartOfYear = (shift = 0, date) => {
  const _date = date ? new Date(date) : new Date();
  return new Date(`${_date.getFullYear() + shift}-01-01`);
};

export const getEndOfYear = (shift = 0, date) => {
  const _date = date ? new Date(date) : new Date();
  return new Date(`${_date.getFullYear() + shift}-12-31`);
};

export const getStartOfMonth = (monthsToAdd = 0) => {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + monthsToAdd,
    1
  );
};

export const getOneDayBeforeNextYearForStartOfMonth = () => {
  const startDate = getStartOfMonth();
  startDate.setDate(startDate.getDate() - 1);
  startDate.setFullYear(startDate.getFullYear() + 1);
  return startDate;
};

export const getStartOfNextMonth = () => {
  const startDate = getStartOfMonth();
  startDate.setMonth(startDate.getMonth() + 1);

  return startDate;
};

export const addOneYear = () => {
  const startDate = getStartOfMonth();
  startDate.setFullYear(startDate.getFullYear() + 1);

  return startDate;
};

export const dateToIsoFormat = (date) => {
  const year = date.getFullYear();
  const month = getMonth(date);
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getMonth = (date) => {
  return (date.getMonth() + 1).toString().padStart(2, "0");
};

/**
 * Returns a new Date object representing a date that is one month after the provided date.
 *
 * @param {Date} date - The initial date.
 * @returns {Date} - A new Date object one month after the input date.
 */
export const addOneMonth = (date) => {
  const newDate = new Date(date);

  newDate.setMonth(date.getMonth() + 1);

  return newDate;
};

export const countMonthsBetweenDates = (start, finish) => {
  let months;
  const startDate = new Date(start);
  const finishDate = new Date(finish);

  months = (finishDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += finishDate.getMonth() + 1;
  return months <= 0 ? 0 : months;
};

export async function sleep(milliseconds) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, milliseconds);
  });
}

export const countDaysBetweenDates = (start, finish) => {
  const startTime = new Date(start).getTime();
  const finishTime = new Date(finish).getTime();

  return Math.floor((finishTime - startTime) / (1000 * 60 * 60 * 24));
};

export const setDayToStringDate = (date, day) => {
  const _date = new Date(date);
  return `${_date.getFullYear()}-${getMonth(_date)}-${
    day < 10 ? "0" : ""
  }${day}`;
};

export const datePresets = {
  [SCHEDULER_FILTER_DATES]: [
    {
      id: "-1W/+3W",
      label: "-1W/+3W",
      default: true,
      start: formatDate(removeWeeksFromCurrentDate(1)),
      finish: formatDate(addWeeksFromCurrentDate(3)),
    },
    {
      id: "+/-1M",
      label: "+/-1M",
      start: formatDate(removeMonthsFromCurrentDate(1)),
      finish: formatDate(addMonthsToCurrentDate(1)),
    },
    {
      id: "+/-2M",
      label: "+/-2M",
      start: formatDate(removeMonthsFromCurrentDate(2)),
      finish: formatDate(addMonthsToCurrentDate(2)),
    },
    {
      id: "+/-3M",
      label: "+/-3M",
      start: formatDate(removeMonthsFromCurrentDate(3)),
      finish: formatDate(addMonthsToCurrentDate(3)),
    },
  ],
  [USER_TASKBOARD_DATE_FILTER]: [
    {
      id: "1D",
      label: "1D",
      default: true,
      start: formatDate(new Date()),
      finish: formatDate(addDays(new Date(), 1)),
    },
    {
      id: "7D",
      label: "7D",
      start: formatDate(getStartOfWeek(new Date())),
      finish: formatDate(addDays(getStartOfWeek(new Date()), 7)),
    },
    {
      id: "14D",
      label: "14D",
      start: formatDate(getStartOfWeek(new Date())),
      finish: formatDate(addDays(getStartOfWeek(new Date()), 14)),
    },
  ],
  [PORTFOLIO_DATE_FILTER]: [
    {
      id: "Last Year",
      label: "Last Year",
      ...getLastYear(),
    },
    {
      id: "Current Year",
      label: "Current Year",
      default: true,
      ...getCurrentYear(),
    },
    {
      id: "Next Year",
      label: "Next Year",
      ...getNextYear(),
    },
    {
      id: "+12 Months",
      label: "+12 Months",
      ...addTwelveMonths(new Date()),
    },
  ],
  [FULL_TIMELINE_YEAR_FILTER]: [
    {
      id: "Last Year",
      label: "Last Year",
      start: formatDate(getStartOfYear(-1)),
      finish: formatDate(getEndOfYear(-1)),
    },
    {
      id: "Current Year",
      label: "Current Year",
      default: true,
      start: formatDate(getStartOfYear(0)),
      finish: formatDate(getEndOfYear(0)),
    },
    {
      id: "Next Year",
      label: "Next Year",
      start: formatDate(getStartOfYear(1)),
      finish: formatDate(getEndOfYear(1)),
    },
    {
      id: "+1 year",
      label: "+1 year",
      start: formatDate(getStartOfMonth(0)),
      finish: formatDate(getOneDayBeforeNextYearForStartOfMonth()),
    },
  ],
  [CUSTOM_TIMELINE_YEAR_FILTER]: [
    {
      id: "Last Year",
      label: "Last Year",
      start: formatDate(getStartOfYear(-1)),
      finish: formatDate(getEndOfYear(-1)),
    },
    {
      id: "Current Year",
      label: "Current Year",
      default: true,
      start: formatDate(getStartOfYear(0)),
      finish: formatDate(getEndOfYear(0)),
    },
    {
      id: "Next Year",
      label: "Next Year",
      start: formatDate(getStartOfYear(1)),
      finish: formatDate(getEndOfYear(1)),
    },
    {
      id: "+6 months",
      label: "+6 months",
      start: formatDate(getStartOfMonth(0)),
      finish: formatDate(getStartOfMonth(6)),
    },
    {
      id: "+1 year",
      label: "+1 year",
      start: formatDate(getStartOfMonth(0)),
      finish: formatDate(getOneDayBeforeNextYearForStartOfMonth()),
    },
  ],
  [CUSTOM_SUPPLY_YEAR_FILTER]: [
    {
      id: "Last Year",
      label: "Last Year",
      start: formatDate(getStartOfYear(-1)),
      finish: formatDate(getEndOfYear(-1)),
    },
    {
      id: "Current Year",
      label: "Current Year",
      default: true,
      start: formatDate(getStartOfYear(0)),
      finish: formatDate(getEndOfYear(0)),
    },
    {
      id: "Next Year",
      label: "Next Year",
      start: formatDate(getStartOfYear(1)),
      finish: formatDate(getEndOfYear(1)),
    },
    {
      id: "+6 months",
      label: "+6 months",
      start: formatDate(getStartOfMonth(0)),
      finish: formatDate(getStartOfMonth(6)),
    },
    {
      id: "+1 year",
      label: "+1 year",
      start: formatDate(getStartOfMonth(0)),
      finish: formatDate(getOneDayBeforeNextYearForStartOfMonth()),
    },
  ],
};

export const getDateRangePreset = (id) => {
  let item = JSON.parse(localStorage.getItem(id));
  const presets = datePresets[id];

  if (item?.id !== "custom") {
    const selectedPreset = presets.find((i) => i.id === item?.id);
    const defaultPreset = presets.find((p) => p.default) || presets[0];
    item = selectedPreset || defaultPreset;
  }

  return item;
};

export const getDateRange = (id) => {
  const preset = getDateRangePreset(id);
  return { start: preset.start, finish: preset.finish };
};

export const saveDateRangePreset = (id, preset) => {
  const item = JSON.stringify(preset);
  return localStorage.setItem(id, item);
};

export const getFullWeekOfDate = (someDate) => {
  const date = new Date(someDate);
  const monday = new Date(date.setDate(date.getDate() - date.getDay() + 1));
  const result = [new Date(monday)];
  while (monday.setDate(monday.getDate() + 1) && monday.getDay() !== 1) {
    result.push(new Date(monday));
  }
  return result;
};

export const getYearsBetweenDates = (firstDate, secondDate) => {
  const firstYear = +firstDate.slice(0, 4);
  const lastYear = +secondDate.slice(0, 4);
  const numberOfYears = lastYear - firstYear + 1;
  const years = new Array(numberOfYears);
  for (let i = 0; i < numberOfYears; i++) years[i] = (firstYear + i).toString();
  return years;
};


/**
 * Calculates the progress percentage based on the given start date, end date, and review date.
 * @param {string} startDate - The start date of the activity in ISO 8601 format (e.g., 'YYYY-MM-DD').
 * @param {string} endDate - The end date of the activity in ISO 8601 format.
 * @param {string} reviewDate - The date of review in ISO 8601 format.
 * @returns {number} The progress percentage.
 */
export function calculateProgressPercentage(startDate, endDate, reviewDate) {
  // Parse string dates into Date objects
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = parseISO(endDate);
  const parsedReviewDate = parseISO(reviewDate);

  // Calculate the number of days between dates
  const totalDays = calculateDaysBetween(parsedStartDate, parsedEndDate);
  const elapsedDays = calculateDaysBetween(parsedStartDate, parsedReviewDate);

  // Calculate progress percentage
  const progressPercentage = (elapsedDays / totalDays) * 100;

  // Clamp the progress percentage between 0 and 100
  return clamp(progressPercentage, 0, 100);
}

/**
 * Calculates the number of days between two dates.
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @returns {number} The number of days between the two dates.
 */
function calculateDaysBetween(startDate, endDate) {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return Math.ceil((endDate - startDate) / millisecondsPerDay);
}

/**
 * Clamps a value between a minimum and maximum.
 * @param {number} value - The value to clamp.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} The clamped value.
 */
function clamp(value, min, max) {
  return Math.min(max, Math.max(min, value));
}

/**
 * function to match syncfusion time picker component with shifts
 */
export function formatHourAsDate(time) {
  const date = new Date();
  const [hour, minute, seconds] = time.split(":");
  date.setHours(+hour);
  date.setMinutes(+minute);
  date.setSeconds(+seconds);
  return date;
}

const convertNumberToStringWithTwoDigits = (num) =>
  num < 10 ? `0${num}` : `${num}`;

export function formatDateAsHour(date) {
  const hours = convertNumberToStringWithTwoDigits(date.getHours());
  const minutes = convertNumberToStringWithTwoDigits(date.getMinutes());
  const seconds = convertNumberToStringWithTwoDigits(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
}

export function assessIfBaselineOfRecordChanged(updatedItem, record) {
  if (
    !updatedItem.timeMetrics.baselineEarlyStartDate ||
    !updatedItem.timeMetrics.baselineEarlyFinishDate ||
    !record.baselines.data[0]
  )
    return false;

  const startOfBaselineChanged = assessIfGivenElementOfBaselineChanged(
    updatedItem,
    record,
    "baselineEarlyStartDate",
    "startDate"
  );
  const finishOfBaselineChanged = assessIfGivenElementOfBaselineChanged(
    updatedItem,
    record,
    "baselineEarlyFinishDate",
    "endDate"
  );
  return startOfBaselineChanged || finishOfBaselineChanged;
}

function assessIfGivenElementOfBaselineChanged(
  updatedItem,
  record,
  timeMetricsField,
  baselineField
) {
  const baselineElement = new Date(record.baselines.data[0][baselineField]);
  const timeMetricsElement = updatedItem.timeMetrics[timeMetricsField];
  return timeMetricsElement !== baselineElement.toISOString().split("T")[0];
}

export function createDateToSetOnBaselinesForRecord(
  previousRecord,
  dateField,
  stringDate
) {
  return createDateWithHoursOfPreviousDateOfRecord(
    previousRecord,
    dateField,
    stringDate,
    true
  );
}

export function createDateToSetOnEarlyDatesForRecord(
  previousRecord,
  dateField,
  stringDate
) {
  return createDateWithHoursOfPreviousDateOfRecord(
    previousRecord,
    dateField,
    stringDate
  );
}

function createDateWithHoursOfPreviousDateOfRecord(
  previousRecord,
  dateField,
  stringDate,
  shouldHandleBaseline = false
) {
  const dateToSet = new Date(stringDate);
  if (shouldHandleBaseline) {
    const previousBaselineDate = previousRecord.baselines.getAt(0)[dateField];
    dateToSet.setHours(previousBaselineDate.getHours());
  } else {
    dateToSet.setHours(previousRecord[dateField].getHours());
  }
  return dateToSet;
}

export function datesAreDifferentBasedOnStringValue(date1, date2) {
  const stringValue1 = new Date(date1).toISOString().split("T")[0];
  const stringValue2 = new Date(date2).toISOString().split("T")[0];
  return stringValue1 !== stringValue2;
}

export function adjustDaysWithHoursOnActivity(activity) {
  activity.earlyStartDate = setHours(
    activity.earlyStartDate,
    DEFAULT_START_OF_DAY
  );
  activity.earlyEndDate = setHours(
    activity.earlyEndDate,
    DEFAULT_FINISH_OF_DAY
  );
  activity.startDate = setHours(activity.startDate, DEFAULT_START_OF_DAY);
  activity.endDate = setHours(activity.endDate, DEFAULT_FINISH_OF_DAY);
  activity.lateStartDate = setHours(
    activity.lateStartDate,
    DEFAULT_START_OF_DAY
  );
  activity.lateEndDate = setHours(activity.lateEndDate, DEFAULT_FINISH_OF_DAY);
}

export function createCalendarPerResource(resources, calendars) {
  return resources.map((resource) => {
    const calendar = calendars.find(
      (cal) => cal.id === resource.selectedCalendarId
    );
    return { ...calendar, id: resource.id, shifts: resource.shifts };
  });
}

export const isYearEqualOrAfterDOR = (dateOfReview, year) => {
  if (!dateOfReview || !year) return false;
  const yearDOR = +(new Date(dateOfReview).getFullYear());
  const currentYear = +year;
  return yearDOR <= currentYear;
}

export function formatDateTime(dateTime) {
  const dateObject = new Date(dateTime);

  const year = dateObject.getFullYear();
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ('0' + dateObject.getDate()).slice(-2);
  const hours = ('0' + dateObject.getHours()).slice(-2);
  const minutes = ('0' + dateObject.getMinutes()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function areDatesSame(dateTimeStr1, dateTimeStr2) {
  const date1 = new Date(dateTimeStr1).toDateString();
  const date2 = new Date(dateTimeStr2).toDateString();

  return date1 === date2;
}
