import {
  GET_G_M_LIST_ERROR,
  GET_G_M_LIST_REQUEST,
  GET_G_M_LIST_SUCCESS,
  UPDATE_G_M_ERROR,
  UPDATE_G_M_LIST_ERROR,
  UPDATE_G_M_LIST_REQUEST,
  UPDATE_G_M_LIST_SUCCESS,
  UPDATE_G_M_REQUEST,
  UPDATE_G_M_SUCCESS,
} from "../types";

const initialState = {
  items: [],
  item: {},
  isFetching: false,
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case UPDATE_G_M_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case UPDATE_G_M_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: payload,
        error: "",
      };
    case UPDATE_G_M_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case UPDATE_G_M_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case UPDATE_G_M_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        //items: payload,
        error: "",
      };
    case UPDATE_G_M_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_G_M_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_G_M_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload,
        error: "",
      };
    case GET_G_M_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
