import React from "react";

const Form = (props) => {
  if (props.formDialog) {
    return (
      <form className={`form  bg-white`} onSubmit={props.onSubmit}>
        {props.children}
      </form>
    );
  } else {
    return (
      <React.Fragment>
        {props.row ? (
          <form className={`form-row mb-5 `} onSubmit={props.onSubmit}>
            {props.children}
          </form>
        ) : (
          <form
            className={`form m-2 p-4 bg-white border border-light shadow-sm`}
            onSubmit={props.onSubmit}
          >
            <h4 className="text-left">{props.title}</h4>
            <hr />
            {props.children}
          </form>
        )}
      </React.Fragment>
    );
  }
};

export default Form;
