import React from "react";
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { JWT_TOKEN } from "../global/constants";
import RedirectGuard from "../hoc/RedirectGuard";
import PermissionGuard from "../hoc/PermissionGuard";
import { Navigate } from "react-router-dom";

const securityCheck = (token) => {
  //console.log(token);
  if(token == null){
        return false ;
  } else {
        let decoded_jwtToken =  jwt_decode(token);
        //console.log(decoded_jwtToken.exp);
        if (Date.now() >= decoded_jwtToken.exp * 1000) {
          return false;
        }
        return true;
   }
  }

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ component: Component, permission, route }) => {
  const { isAuth, error } = useSelector(state => state.security);
  const token = localStorage.getItem(JWT_TOKEN);

  if(token != null && !error && securityCheck(token)){
    return (
      <React.Fragment>
        <RedirectGuard>
          <PermissionGuard permission={permission}>
            <Component />
          </PermissionGuard>
        </RedirectGuard>
      </React.Fragment>
    );
  } else {
    sessionStorage.setItem("session-is-expired", true);
    route && sessionStorage.setItem("current-page", route);
    return <Navigate to="/logout" />;
  }
};
