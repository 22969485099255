import { combineReducers } from "redux";
import errorReducer from "../../services/error-services/errorReducer";
import blockReducer from "../../services/block-services/blockReducer";
import patternReducer from "../../services/pattern-services/patternReducer";
import projectReducer from "../../services/project-services/projectReducer";
import securityReducer from "../../services/security-services/securityReducer";
import insightsReducer from "../../services/insights-services/insightsReducer";
import structureReducer from "../../services/structure-services/structureReducer";
import activityReducer from "../../services/activity-services/activityReducer";
import { 
  scenarioReducer, 
  scenarioMetricsReducer 
} from "../../services/scenario-services/scenarioReducer";
import officeReducer from "../../services/office-services/officeReducer";
import dependencyReducer from "../../services/dependency-services/dependencyReducer";
import scheduleActivityReducer from "../../services/schedule-activity-services/scheduleActivityReducer";
import resourceReducer from "../../services/resource-services/resourceReducer";
import attributeReducer from "../../services/attribute-services/attributeReducer";
import quantityReducer from "../../services/quantity-services/quantityReducer";
import calendarReducer from "../../services/calendar-service/calendarReducer";
import userReducer from "../../services/user-services/userReducer";
import usageReducer from "../../services/usage-services/usageReducer";
import tenantsReducer from "../../services/tenant-services/tenantReducer";
import currentUserReducer from "../../services/currentUser/currentUserReducer";
import genericMetricsReducer from "../../services/generic-metrics-services/genericMetricsReducer";
import objectiveReducer from "../../services/objective-service/objectiveReducer";
import {
  UtilsReducer,
  UtilsReducer2,
  UtilsReducer3,
  UtilsReducer4
} from "../../services/utils-services/utilsReducer";
import standardReducer from "../../services/standard-services/standardReducer";
import tagReducer from "../../services/tag-services/tagReducer";
import VisualPlanningReducer from "../../services/visual-planning-services/visualPlanningReducer";
import VisualInfoReducer from "../../services/visual-info-services/visualInfoReducer";
import MovieReducer from "../../services/movie-services/movieReducer";
import SchedulerReducer from "../../services/scheduler-services/schedulerReducer";
import PortfolioReducer from "../../services/portfolio-services/portfolioReducer";
import aiReducer from "../../services/ai-services/aiReducer";
import ResourceSpectrumReducer from "../../services/resource-spectrum-services/resourceSpectrumReducer";
import timelineReducer from "../../services/timeline-services/timelineReducer";
import fontReducer from "../../services/font-services/fontReducer";
import columnPermissionReducer from "../../services/column-permission-services/columnPermissionReducer";

export default combineReducers({
  errors: errorReducer,
  block: blockReducer,
  pattern: patternReducer,
  project: projectReducer,
  security: securityReducer,
  insights: insightsReducer,
  structure: structureReducer,
  activity: activityReducer,
  scenario: scenarioReducer,
  scenarioMetrics: scenarioMetricsReducer,
  office: officeReducer,
  dependency: dependencyReducer,
  scheduleActivity: scheduleActivityReducer,
  resource: resourceReducer,
  attribute: attributeReducer,
  quantity: quantityReducer,
  calendar: calendarReducer,
  usage: usageReducer,
  users: userReducer,
  tenants: tenantsReducer,
  currentUser: currentUserReducer,
  genericMetrics: genericMetricsReducer,
  objective: objectiveReducer,
  standard: standardReducer,
  tag: tagReducer,
  utils: UtilsReducer,
  utils2: UtilsReducer2,
  utils3: UtilsReducer3,
  utils4: UtilsReducer4,
  visualInfo: VisualInfoReducer,
  visualPlanning: VisualPlanningReducer,
  movie: MovieReducer,
  scheduler: SchedulerReducer,
  portfolio: PortfolioReducer,
  ai: aiReducer,
  resourceSpectrum: ResourceSpectrumReducer,
  timeline: timelineReducer,
  fonts: fontReducer,
  columnPermissions: columnPermissionReducer
});
