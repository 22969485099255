import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = (props) => {
  return (
    <ToastContainer
      position="bottom-left"
      width="auto"
      height="80px"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable={false}
      pauseOnHover={true}
    />
  );
};

export default Toaster;
