import React, { Component } from "react";
import { logout } from "../../../services/security-services/SecurityActions";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }
  render() {
    return <Navigate to="/login" />;
  }
}

Logout.propType = {
  logout: PropTypes.func.isRequired
};

export default connect(null, { logout })(Logout);
