import { multiply, sum } from "../MathUtils/MathUtils";
import { conditionalCompute } from "../ObjectUtils/ObjectUtils";

const computeResourceAllocationForRemainingMonths = (
  forecast,
  remainingMonthsCoefficient,
  yearlyFTE
) => {
  return multiply(forecast, remainingMonthsCoefficient, yearlyFTE);
};

export const computeBudgetAtCompletion = ({
  forecast,
  remainingMonthsCoefficient,
  yearlyFTE,
}) => {
  return conditionalCompute(
    [forecast, yearlyFTE, remainingMonthsCoefficient],
    () =>
      computeResourceAllocationForRemainingMonths(
        forecast,
        remainingMonthsCoefficient,
        yearlyFTE
      ),
    0
  );
};

export const computeBudgetAtCompletionOnInlineUpdate = (
  { remainingMonthsCoefficient, yearlyFTE },
  forecast
) => {
  return conditionalCompute(
    [forecast, yearlyFTE, remainingMonthsCoefficient],
    () =>
      computeResourceAllocationForRemainingMonths(
        forecast,
        remainingMonthsCoefficient,
        yearlyFTE
      ),
    0
  );
};
