import { JWT_TOKEN } from "../../global/constants";
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "../types";

const initialState = {
  isFetching: false,
  isAuth: false,
  user: {},
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuth: true,
        error: "",
        user: action.payload,
      };
    case LOGIN_ERROR:
      localStorage.removeItem(JWT_TOKEN);
      return {
        ...state,
        isFetching: false,
        isAuth: false,
        error: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem(JWT_TOKEN);
      return {
        ...state,
        isFetching: false,
        isAuth: false,
        user: {},
      };
    default:
      return state;
  }
}
