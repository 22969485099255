import {
  TENANT_CREATE_REQUEST,
  TENANT_CREATE_SUCCESS,
  TENANT_CREATE_ERROR,
  GET_TENANTS_REQUEST,
  GET_TENANTS_SUCCESS,
  GET_TENANTS_ERROR,
  TENANT_DELETE_REQUEST,
  TENANT_DELETE_SUCCESS,
  TENANT_DELETE_ERROR,
  TENANT_UPDATE_REQUEST,
  TENANT_UPDATE_SUCCESS,
  TENANT_UPDATE_ERROR,
} from "../types";

const initialState = {
  items: [],
  isFetching: false,
  error: "",
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TENANT_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case TENANT_CREATE_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload],
        isFetching: false,
      };
    case TENANT_CREATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case GET_TENANTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case GET_TENANTS_SUCCESS:
      return {
        ...state,
        items: payload,
        isFetching: false,
      };
    case GET_TENANTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case TENANT_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case TENANT_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload),
        isFetching: false,
      };
    case TENANT_DELETE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case TENANT_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: "",
      };
    case TENANT_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }
          return item;
        }),
        isFetching: false,
      };
    case TENANT_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
};
