import {
  GET_VISUAL_INFO_BY_STRUCTURE_REQUEST,
  GET_VISUAL_INFO_BY_STRUCTURE_SUCCESS,
  GET_VISUAL_INFO_BY_STRUCTURE_ERROR,
  CREATE_VISUAL_INFO_REQUEST,
  CREATE_VISUAL_INFO_SUCCESS,
  CREATE_VISUAL_INFO_ERROR,
  UPDATE_VISUAL_INFO_REQUEST,
  UPDATE_VISUAL_INFO_SUCCESS,
  UPDATE_VISUAL_INFO_ERROR,
  UPDATE_ALL_VISUAL_INFO_REQUEST, 
  UPDATE_ALL_VISUAL_INFO_SUCCESS, 
  UPDATE_ALL_VISUAL_INFO_ERROR,
  DELETE_VISUAL_INFO_REQUEST,
  DELETE_VISUAL_INFO_SUCCESS,
  DELETE_VISUAL_INFO_ERROR,
  CLEAR_VISUAL_INFO
} from "../types";
  
  const initialState = {
    items: [],
    isFetching: false,
    error: "",
  };
  
  // eslint-disable-next-line
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_VISUAL_INFO_BY_STRUCTURE_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: "",
        };
      case GET_VISUAL_INFO_BY_STRUCTURE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: payload || [],
        };
      case GET_VISUAL_INFO_BY_STRUCTURE_ERROR:
        return {
          ...state,
          isFetching: false,
          error: payload,
        };
      case CREATE_VISUAL_INFO_REQUEST:
        return {
          ...state,
          error: "",
        };
      case CREATE_VISUAL_INFO_SUCCESS:
        return {
          ...state,
          items: [...state.items, payload],      
        };
      case CREATE_VISUAL_INFO_ERROR:
        return {
          ...state,
          error: payload,
        };
      case UPDATE_VISUAL_INFO_REQUEST:
        return {
          ...state,
          error: "",
        };
      case UPDATE_VISUAL_INFO_SUCCESS:
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.id === payload.id) {
              return { ...item, ...payload };
            }
            return item;
          }),     
        };
      case UPDATE_VISUAL_INFO_ERROR:
        return {
          ...state,
          error: payload,
        };
      case UPDATE_ALL_VISUAL_INFO_REQUEST:
        return {
          ...state,
          error: "",
        };
      case UPDATE_ALL_VISUAL_INFO_SUCCESS:
        return {
          ...state,    
        };
      case UPDATE_ALL_VISUAL_INFO_ERROR:
        return {
          ...state,
          error: payload,
        };
      case DELETE_VISUAL_INFO_REQUEST:
        return {
          ...state,
          error: "",
        };
      case DELETE_VISUAL_INFO_SUCCESS:
        return {
          ...state,
          items: state.items.filter((item) => item.id !== payload),     
        };
      case DELETE_VISUAL_INFO_ERROR:
        return {
          ...state,
          error: payload,
        };
      case CLEAR_VISUAL_INFO:
        return {
          ...state,
          items: [],
        };
      default:
        return state;
    }
  };
  