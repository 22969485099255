import {
  SET_PROJECT_MOVIE_SETTINGS,
  SET_MOVIE_FRAMES,
  SET_CURRENT_MOVIE_INDEX,
  CLEAR_PROJECT_MOVIE
} from "../types";
  
const initialState = {
  frames: [],
  currentIndex: 0,
  frequency: undefined, 
  delay: undefined,
  initialDate: undefined
};

// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_PROJECT_MOVIE_SETTINGS: 
      return {
        ...state, 
        frames: [],
        currentIndex: 0,
        frequency: payload.frequency,
        delay: payload.delay,
        initialDate: payload.initialDate,
        isMoviePlaying: true
      }
    case SET_MOVIE_FRAMES:
      return {
        ...state,
        frames: payload,
      };
    case SET_CURRENT_MOVIE_INDEX:
      return {
        ...state,
        currentIndex: payload,
      };
    case CLEAR_PROJECT_MOVIE: 
      return {
        ...state, 
        frames: [],
        currentIndex: 0,
        frequency: undefined, 
        delay: undefined,
        initialDate: undefined,
        isMoviePlaying: false
      };
    default:
      return state;
  }
}
  