import {
  GET_CHAPTER_DATA,
  GET_PACKAGE_DATA,
  GET_AVERAGE_DURATION,
  GET_AVERAGE_COST,
  GET_AVERAGE_WORK,
  GET_AVERAGE_FTE,
  GET_RELIABILITY_SCORE,
  GET_CHANCE_OF_SUCCESS,
} from "../types";

const initialState = {
  chapterData: [],
  packageData: [],
  averageDuration: "",
  averageWork: "",
  averageCost: "",
  averageFTE: "",
  reliabilityScore: "",
  chanceOfSuccess: "",
};
// eslint-disable-next-line
export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_CHAPTER_DATA:
      return {
        ...state,
        chapterData: actions.payload,
      };

    case GET_PACKAGE_DATA:
      return {
        ...state,
        packageData: actions.payload,
      };

    case GET_AVERAGE_DURATION:
      return {
        ...state,
        averageDuration: actions.payload,
      };

    case GET_AVERAGE_WORK:
      return {
        ...state,
        averageWork: actions.payload,
      };

    case GET_AVERAGE_COST:
      return {
        ...state,
        averageCost: actions.payload,
      };
    case GET_AVERAGE_FTE:
      return {
        ...state,
        averageFTE: actions.payload,
      };
    case GET_RELIABILITY_SCORE:
      return {
        ...state,
        reliabilityScore: actions.payload,
      };

    case GET_CHANCE_OF_SUCCESS:
      return {
        ...state,
        chanceOfSuccess: actions.payload,
      };
    default:
      return state;
  }
}
